import { Layouts, Loading } from "@components";
import { Form, Input, Button, Divider, message, Alert } from "antd";
import MaskedInput from "antd-mask-input";
import { useState, useEffect } from "react";
import * as API from "@api";
import * as R from "ramda";

export default function Profile() {
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(null);
  const [artist, setArtist] = useState(null);

  useEffect(() => {
    const getInitData = async () => {
      const [user, artist] = await Promise.all([
        API.Users.getDetails(),
        API.Artists.getArtist(),
      ]);

      if (user.error) {
        return message.error("Невозможно загрузить данные", 1);
      }

      if (artist?.data) {
        setArtist(artist.data);
      }

      setFormData(user.data);
      setIsLoading(false);
    };

    getInitData();
  }, []);

  const onDetailsUpdate = async (data) => {
    setIsLoading(true);

    if (data.tel) {
      data.tel = data.tel.replace(/[+, ]/g, "");
    }

    const _res = await API.Users.updateSelf(data);
    setIsLoading(false);

    if (_res.error) {
      return message.error("Произошла ошибка", 1);
    }

    return message.success("Данные успешно обновлены", 1);
  };

  const onPasswordUpdate = (data) => {
    if (data.password.length < 6) {
      return message.error("Пароль должен быть не менее 6 символов", 1);
    }

    if (data.password !== data.password_confirm) {
      return message.error("Пароли не совпадают", 1);
    }

    onDetailsUpdate(data);
  };

  if (!formData) {
    return (
      <Layouts.ArtistLayout disableInteraction>
        <Loading />
      </Layouts.ArtistLayout>
    );
  }

  const detailsform = (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onDetailsUpdate}
      onFinishFailed={() => {}}
      autoComplete="off"
      style={{ maxWidth: 500, margin: "50px 0" }}
      labelAlign="left"
      initialValues={formData}
    >
      <Form.Item
        label="Имя"
        name="first_name"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Фамилия"
        name="last_name"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Эл.почта"
        name="email"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input htmlType="email" />
      </Form.Item>

      <Divider />

      <Form.Item className="mt-4">
        <Button loading={isLoading} type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );

  const telform = (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onDetailsUpdate}
      onFinishFailed={() => {}}
      autoComplete="off"
      style={{ maxWidth: 500, margin: "50px 0" }}
      labelAlign="left"
      initialValues={formData}
    >
      <Form.Item
        label="Телефон"
        name="tel"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <MaskedInput
          mask="+7 111 1111111"
          placeholder="Телефон (напр. +7 701 1234567)"
        />
      </Form.Item>

      <Divider />

      <Form.Item className="mt-4">
        <Button loading={isLoading} type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );

  const passwordform = (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onPasswordUpdate}
      onFinishFailed={() => {}}
      autoComplete="off"
      style={{ maxWidth: 500, margin: "50px 0" }}
      labelAlign="left"
    >
      <Form.Item
        label="Новый пароль"
        name="password"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input.Password placeholder="Введите новый пароль" />
      </Form.Item>

      <Form.Item
        label="Повторите пароль"
        name="password_confirm"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input.Password placeholder="Повторите новый пароль" />
      </Form.Item>

      <Divider />

      <Form.Item className="mt-4">
        <Button loading={isLoading} type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Layouts.ArtistLayout>
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2 className="m-0">Данные пользователя</h2>
          {detailsform}
          <h2>Номер телефона</h2>
          {telform}
          <h2>Пароль</h2>
          {passwordform}
        </div>
        <div className="col-12 col-lg-6">
          <h2>Данные артиста</h2>
          <Alert
            className="mt-5 mb-5"
            message="Внимание"
            description="Если вам необходимо поменять данные исполнителя, пожалуйста, обратитесь к менеджеру за помощью."
            type="warning"
            showIcon
          />
          <div className="mb-4">
            <h4>Псевдоним</h4>
            <span className="d-block">
              {R.pathOr("Нет данных", ["name"], artist)}
            </span>
          </div>
          <div className="mb-4">
            <h4>Описание</h4>
            <span className="d-block">
              {R.pathOr("Нет данных", ["description"], artist)}
            </span>
          </div>
          <div className="row">
            <div className="col-6 mb-4">
              <h4>Наименование ТОО/ИП/Физ.лица</h4>
              <span>
                {R.pathOr("Нет данных", ["company", "name"], formData)}
              </span>
            </div>
            <div className="col-6 mb-4">
              <h4>БИН/ИИН</h4>
              <span>
                {R.pathOr("Нет данных", ["company", "bin"], formData)}
              </span>
            </div>
            <div className="col-6 mb-4">
              <h4>Адрес</h4>
              <span>
                {R.pathOr("Нет данных", ["company", "address"], formData)}
              </span>
            </div>
            <div className="col-6 mb-4">
              <h4>Уд. личности</h4>
              <span className="d-block">
                ИИН:{" "}
                {R.pathOr("Нет данных", ["company", "id", "iin"], formData)}
              </span>
              <span className="d-block">
                No.:{" "}
                {R.pathOr("Нет данных", ["company", "id", "number"], formData)}
              </span>
              <span className="d-block">
                Выдано:{" "}
                {R.pathOr("Нет данных", ["company", "id", "issuer"], formData)}{" "}
                {R.pathOr(
                  "Нет данных",
                  ["company", "id", "issue_date"],
                  formData
                )}
              </span>
            </div>
          </div>
          <div className="mb-4">
            <h4>Договоры и соглашения</h4>
            {R.pathOr([], ["contracts"], artist).map((contract) => (
              <li>
                <a target="_blank" href={`/contracts/view/${contract.id}`}>
                  {R.pathOr("N/a", ["template", "name"], contract)}
                </a>
              </li>
            ))}
          </div>
        </div>
      </div>
    </Layouts.ArtistLayout>
  );
}
