import { Layouts, Loading, ReportModal, DocModal } from "@components";
import {
  Card,
  message,
  Button,
  Tabs,
  Typography,
  List,
  Tag,
  Divider,
} from "antd";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GlobalOutlined } from "@ant-design/icons";
import moment from "moment";
import * as API from "@api/admin";
import * as R from "ramda";
import "moment/locale/ru";

const { TabPane } = Tabs;

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [applications, setApplications] = useState([]);
  const [reports, setReports] = useState([]);
  const [docs, setDocs] = useState(null);

  useEffect(() => {
    async function fetchInitData() {
      const _res = {
        user: await API.Users.getDetails(),
        applications: await API.Applications.getApplications({ limit: 5 }),
        documents: await API.Docs.getAdminIncomingDocs({ limit: 5 }),
        reports: await API.Reports.getReports({ limit: 5 }),
      };

      if (
        _res.user.error ||
        _res.applications.error ||
        _res.documents.error ||
        _res.reports.error
      ) {
        return message.error("Невозможно загрузить данные", 2);
      }

      setUser(_res.user.data);
      setApplications(_res.applications.data);
      setDocs(_res.documents.data);
      setReports(_res.reports.data);
      setIsLoading(false);
    }

    fetchInitData();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Layouts.AdminLayout className="dashboard">
      <div
        className="dashboard-hero"
        style={{
          background: "url('/img/hero-dashboard.jpg') center center no-repeat",
          backgroundSize: "cover",
        }}
      >
        <h1>Добро пожаловать, {user.first_name} 👋</h1>
        <p>
          Платформа дистрибуции позволяет обмениваться документами, создавать
          отчеты, контролировать треки и альбомы артистов и подписывать
          документы онлайн. Это быстрее, удобнее, а главное, теперь нет
          необходимости встречаться вживую.
        </p>
        <Link to="/reports">
          <Button>Все отчеты</Button>
        </Link>
        <Divider type="vertical" />
        <Link to="/artists">
          <Button>Все артисты</Button>
        </Link>
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <Card
            title="Последние заявки на регистрацию"
            extra={
              <Link to="/applications">
                <Button type="primary">Все заявки</Button>
              </Link>
            }
          >
            <List
              itemLayout="vertical"
              dataSource={applications}
              renderItem={(item) => (
                <List.Item>
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <Typography.Title className="m-0 mb-1" level={5} strong>
                      {item.name}
                    </Typography.Title>
                    <Link to={`/applications/view/${item.id}`}>
                      <Button size="small">Открыть</Button>
                    </Link>
                  </div>
                  <Typography.Text>
                    Описание:{" "}
                    <p className="m-0">{item.description || "Без описания"}</p>
                  </Typography.Text>
                  <Typography.Text>
                    Пользователь:{" "}
                    <Link to={`/users/view/${item.user.id}`}>
                      {item.user.first_name} {item.user.last_name}
                    </Link>
                  </Typography.Text>
                  <br />
                  <div className="d-flex mt-2 mb-2 justify-content-start align-items-center flex-wrap">
                    {item.status === "new" ? (
                      <Tag color="blue">Новый</Tag>
                    ) : (
                      <Tag>Просмотрен</Tag>
                    )}
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </div>
        <div className="col-12 col-lg-6 mb-3">
          <Card
            title="Последние отчеты"
            extra={
              <Link to="/reports">
                <Button type="primary">Все отчеты</Button>
              </Link>
            }
          >
            <List
              itemLayout="vertical"
              dataSource={reports}
              renderItem={(item) => (
                <List.Item>
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <Typography.Title className="m-0 mb-1" level={5} strong>
                      {item.name}
                    </Typography.Title>
                    <Link to={`/reports/edit/${item.id}`}>
                      <Button size="small">Открыть</Button>
                    </Link>
                  </div>
                  <Typography.Text>
                    Артист:{" "}
                    <Link to={`/artists/view/${item.artist.id}`}>
                      <a>{item.artist.name}</a>
                    </Link>
                  </Typography.Text>
                  <br />
                  <Typography.Text>
                    Период:{" "}
                    {moment(item.period_start).locale("ru").format("MMMM YYYY")}{" "}
                    — {moment(item.period_end).locale("ru").format("MMMM YYYY")}
                  </Typography.Text>
                  <br />
                  <Typography.Text>
                    Создан:{" "}
                    {moment(item.createdAt)
                      .locale("ru")
                      .format("DD MMMM YYYY HH:mm")}
                  </Typography.Text>
                  <div className="d-flex mt-2 mb-2 justify-content-start align-items-center flex-wrap">
                    {item.status === "new" ? (
                      <Tag color="blue">Не просматривался артистом</Tag>
                    ) : (
                      <Tag color="green">Просмотрен артистом</Tag>
                    )}
                    {item.requires_artist_signature &&
                      (item.artist_signed ? (
                        <Tag color="green">Документ подписан артистом</Tag>
                      ) : (
                        <Tag color="orange">Ожидание подписи артиста</Tag>
                      ))}
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </div>
        <div className="col-12 col-lg-6 mb-3">
          <Card
            title="Последние документы"
            extra={
              <Link to="/docs">
                <Button type="primary">Все документы</Button>
              </Link>
            }
          >
            <List
              itemLayout="vertical"
              dataSource={docs}
              renderItem={(item) => (
                <List.Item>
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <Typography.Title className="m-0 mb-1" level={5} strong>
                      {item.name}
                    </Typography.Title>
                    <Link to={`/docs/edit/${item.id}`}>
                      <Button size="small">Открыть</Button>
                    </Link>
                  </div>
                  <Typography.Text>
                    Создан:{" "}
                    {moment(item.createdAt)
                      .locale("ru")
                      .format("DD MMMM YYYY HH:mm")}
                  </Typography.Text>
                  <div className="d-flex mt-2 mb-2 justify-content-start align-items-center flex-wrap">
                    {item.status === "new" ? (
                      <Tag color="blue">Новый</Tag>
                    ) : (
                      <Tag>Просмотрен</Tag>
                    )}
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </div>
      </div>
    </Layouts.AdminLayout>
  );
}
