import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import App from "./App";

import ruRU from "antd/lib/locale/ru_RU";

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={ruRU}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
