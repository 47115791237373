import { Layouts, Loading } from "@components";
import { Form, Input, Button, Upload, Divider, Select, message } from "antd";
import { useParams } from "react-router-dom";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { PageHeader } from "antd";
import * as API from "@api/admin";
import * as R from "ramda";

export default function EditArtist() {
  const [isLoading, setIsLoading] = useState(true);
  const [artist, setArtist] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    // Подгружаем данные артиста
    async function fetchInitData() {
      let _res = await API.Artists.getArtist({ id });

      if (_res.error) {
        return message.error("Невозможно загрузить данные с сервера", 2);
      }
      setArtist(_res.data);
      setIsLoading(false);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    // Формируем данные формы для отправки
    setIsLoading(true);
    const formData = new FormData();
    formData.append("id", artist.id);
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("status", data.status);

    if (R.pathOr(null, ["photo", "file"], data)) {
      formData.append("photo", data.photo.file);
    }

    const _res = await API.Artists.updateArtist({
      id: artist.id,
      data: formData,
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Не удалось обновить данные", 2);
    }

    message.success("Данные обновлены", 1, () => document.location.reload());
  };

  const form = (
    <Form
      name="artist"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFormSubmit}
      onFinishFailed={() => {}}
      initialValues={artist}
      style={{ maxWidth: 500, margin: "20px auto" }}
      labelAlign="left"
    >
      <Form.Item name="id" hidden>
        <Input htmlType="hidden" disabled />
      </Form.Item>

      <Form.Item
        label="Название"
        name="name"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Описание"
        name="description"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>

      <Form.Item
        extra="Выставите статус для данного артиста"
        label="Статус"
        name="status"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Select allowClear={false}>
          <Select.Option value="active">Активный</Select.Option>
          <Select.Option value="inactive">Неактивный</Select.Option>
        </Select>
      </Form.Item>

      <Divider />

      <Form.Item
        name="photo"
        label="Изображение"
        extra="Загрузите, чтобы заменить текущее изображение"
      >
        <Upload
          beforeUpload={() => false}
          accept="image/*"
          name="photo"
          listType="picture"
        >
          <Button icon={<UploadOutlined />}>Выбрать изображение</Button>
        </Upload>
      </Form.Item>

      <Divider />

      <Form.Item className="mt-4">
        <Button
          loading={isLoading}
          icon={<SaveOutlined />}
          type="primary"
          htmlType="submit"
        >
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Layouts.AdminLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Данные артиста"
        subTitle="Редактируйте данные артиста"
      />
      {isLoading ? <Loading /> : form}
    </Layouts.AdminLayout>
  );
}
