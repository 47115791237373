import { useState, useRef, useEffect } from "react";
import { Layouts } from "@components";
import {
  Form,
  Input,
  Button,
  Upload,
  Divider,
  Checkbox,
  Select,
  message,
} from "antd";
import { UploadOutlined, RedoOutlined } from "@ant-design/icons";
import SignatureCanvas from "react-signature-canvas";
import MaskedInput from "antd-mask-input";
import * as API from "@api";
import * as R from "ramda";

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export default function ArtistRegistrationPage(params) {
  const [isLoading, setIsLoading] = useState(false);
  const [companyType, setCompanyType] = useState("individual");
  const signatureCanvas = useRef(null);

  useEffect(() => {
    async function checkApplications() {
      const res = await API.Applications.getApplications();

      // if (R.filter(R.propEq("status", "published"), res.data).length > 0) {
      //   window.location.href = "/";
      //   return;
      // }

      if (R.filter(R.propEq("status", "new"), res.data).length > 0) {
        window.location.href = "/pending";
        return;
      }

      if (R.filter(R.propEq("status", "rejected"), res.data).length > 0) {
        return message.error(
          "Одна из ваших заявок была отклонена нашим специалистом. Мы свяжемся с вами в ближайшее время, чтобы уточнить причины отказа.",
          5
        );
      }
    }

    checkApplications();
  }, []);

  const onSignatureReset = () => {
    return signatureCanvas.current.clear();
  };

  const onFormSubmit = async (data) => {
    setIsLoading(true);
    const signature = signatureCanvas.current;

    if (!signature || signature.isEmpty()) {
      setIsLoading(false);
      return message.error("Пожалуйста, оставьте свою цифровую подпись.", 2);
    }

    // const signatureData = dataURItoBlob(signature.toDataURL("image/svg+xml"));
    const signatureData = dataURItoBlob(signature.toDataURL("image/png"));

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("name_en", data.name_en);
    formData.append(
      "signature",
      new File([signatureData], "signature.png", {
        type: "image/png",
      })
    );

    formData.append(
      "company",
      JSON.stringify({
        name: data.company_name,
        type: data.company_type,
        bin: data.company_bin || data.id_iin,
        address: data.company_address || "N/a",
        id: {
          iin: data.id_iin,
          issue_date: data.id_issue_date,
          issuer: data.id_issuer,
          number: data.id_number,
        },
      })
    );

    if (data.description) {
      formData.append("description", data.description);
    }

    if (data.image) {
      formData.append("image", data.image.file);
    }

    if (data.track) {
      formData.append("track", data.track.file);
    }

    const res = await API.Applications.submitApplication(formData);
    setIsLoading(false);

    if (res.error) {
      return message.error(
        "Что-то пошло не так. Попробуйте снова или обратитесь в поддержку.",
        2
      );
    }

    return message.success("Заявка успешно создана", () =>
      document.location.reload()
    );
  };

  const artistform = (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFormSubmit}
      onFinishFailed={() => {}}
      autoComplete="off"
      style={{ maxWidth: 500, margin: "50px auto" }}
      labelAlign="left"
      scrollToFirstError
    >
      <Form.Item
        label="Название"
        name="name"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input placeholder="Название артиста или группы" />
      </Form.Item>

      <Form.Item
        label="Название на англ."
        name="name_en"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input placeholder="Название на английском" />
      </Form.Item>

      <Form.Item
        label="Небольшое описание"
        name="description"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input.TextArea placeholder="Расскажите немного об артисте / группе" />
      </Form.Item>

      <Divider />

      <Form.Item
        name="image"
        label="Изображение"
        extra="Выберите изображение (макс. 5 МБ)"
      >
        <Upload
          beforeUpload={() => false}
          accept="image/*"
          name="image"
          listType="picture"
        >
          <Button icon={<UploadOutlined />}>Выбрать изображение</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="track"
        label="Превью-трек"
        extra="Выберите превью-трек (макс. 15 МБ)"
      >
        <Upload beforeUpload={() => false} accept=".mp3,audio/*" name="track">
          <Button icon={<UploadOutlined />}>Выбрать аудиофайл</Button>
        </Upload>
      </Form.Item>

      <Divider />

      <h3>Данные о заявителе</h3>
      <p>
        Выберите как вы хотите работать с нами (физ.лицо, ИП или ТОО) и
        заполните необходимые данные.
      </p>

      <Form.Item
        extra="Выберите вид организации"
        label="Вид организации"
        name="company_type"
        initialValue={companyType}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Select allowClear={false} onChange={(val) => setCompanyType(val)}>
          <Select.Option value="individual">Физ. лицо</Select.Option>
          <Select.Option value="ip">ИП</Select.Option>
          <Select.Option value="too">ТОО</Select.Option>
        </Select>
      </Form.Item>

      <h4>Удостоверение личности</h4>

      <Form.Item
        extra="Введите номер вашего уд. личности"
        label="Номер уд. личности"
        name="id_number"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input type="number" prefix="№" />
      </Form.Item>

      <Form.Item
        extra="Введите ваш ИИН"
        label="ИИН"
        name="id_iin"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input placeholder="123456789012" minLength={12} maxLength={12} />
      </Form.Item>

      <Form.Item
        extra="Напишите кем выдано ваше уд. личности (напрмер, МВД РК)"
        label="Кем выдан"
        name="id_issuer"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input placeholder="Напр. МВД РК" />
      </Form.Item>

      <Form.Item
        extra="Напишите дату выдачи уд.личности (напр. 04.2020)"
        label="Когда выдан"
        name="id_issue_date"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <MaskedInput mask="11.1111" placeholder="04.2020" />
      </Form.Item>

      {companyType !== "individual" && (
        <Form.Item
          extra={"Введите название ИП / ТОО полностью"}
          label="Название организации"
          name="company_name"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input placeholder="Напр. ИП Иванов Иван или ТОО GAKKU" />
        </Form.Item>
      )}

      {companyType === "too" && (
        <Form.Item
          extra={"Введите БИН компании"}
          label="БИН"
          name="company_bin"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input />
        </Form.Item>
      )}

      <Form.Item
        extra={"Введите адрес проживания (или офиса если ИП/ТОО)"}
        label="Адрес"
        name="company_address"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input.TextArea
          rows={3}
          placeholder="Напр. Ул. Пушкина, д.4, 010000, г. Нур-Султан"
        />
      </Form.Item>

      <Divider />

      <h3>Ваша подпись</h3>
      <p>
        Оставьте свою подпись в окошке ниже. Постарайтесь не выходить за рамки
        окна. Далее, используя вашу цифровую версию подписи, вы можете
        подписывать отчеты, акты и другие документы на платформе.
      </p>

      <div className="signature-canvas">
        <SignatureCanvas
          ref={signatureCanvas}
          canvasProps={{ width: 300, height: 150 }}
        />
        <Button
          onClick={onSignatureReset}
          className="btn"
          shape="circle"
          icon={<RedoOutlined />}
        />
      </div>

      <Divider />

      <h3>Условия пользования платформой</h3>
      <p>
        Чтобы начать пользоваться платформой, вам необходимо дать согласие на
        условия ниже:
      </p>

      <Form.Item
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error("Обязательно к заполнению")),
          },
        ]}
        name="consent-contract"
      >
        <Checkbox>Согласен(на) с условиями Договора</Checkbox>
      </Form.Item>

      <Form.Item
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error("Обязательно к заполнению")),
          },
        ]}
        name="consent-data-privacy"
      >
        <Checkbox>Согласен(на) с условиями обработки данных платформы</Checkbox>
      </Form.Item>

      <Form.Item
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error("Обязательно к заполнению")),
          },
        ]}
        name="consent-signature-usage"
      >
        <Checkbox>Согласен(на) на использование цифровой подписи</Checkbox>
      </Form.Item>

      <Divider />

      <Form.Item className="mt-4">
        <Button loading={isLoading} type="primary" htmlType="submit">
          Отправить
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Layouts.ArtistLayout disableInteraction>
      <h2 className="m-0">Регистрация исполнителя</h2>
      <p className="mt-1">
        Добро пожаловать на страницу регистрации исполнителя! Пожалуйста,
        введите данные исполнителя, чтобы продолжить.
      </p>
      {artistform}
    </Layouts.ArtistLayout>
  );
}
