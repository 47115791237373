import { Layouts } from "@components";
import { Form, Input, Button, Upload, Divider, Select, message } from "antd";
import { useHistory } from "react-router-dom";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { PageHeader } from "antd";
import * as API from "@api";
import moment from "moment";

export default function ArtistDocNew() {
  const [isLoading, setIsLoading] = useState(true);
  const [docType, setDocType] = useState(1);
  const [docs, setDocs] = useState([]);
  const router = useHistory();

  useEffect(() => {
    async function fetchInitData() {
      const _res = {
        incoming: await API.Docs.getIncomingDocs(),
        outgoing: await API.Docs.getOutgoingDocs(),
      };

      setIsLoading(false);

      if (_res.incoming.error || _res.outgoing.error) {
        return message.error("Невозможно загрузить документы", 2);
      }

      setDocs([..._res.incoming.data, ..._res.outgoing.data]);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("type", data.type);

    if (data.parent_id) {
      formData.append("parent_id", data.parent_id);
    }

    if (data.type === 1) {
      formData.append("document", data.document.file);
    } else {
      formData.append("url", data.document);
    }

    const _res = await API.Docs.createDoc(formData);

    setIsLoading(false);

    if (_res.error) {
      return message.error("Не удалось создать документ", 2);
    }

    message.success("Документ создан", 1, () => router.push("/docs"));
  };

  return (
    <Layouts.ArtistLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Новый документ"
        subTitle="Загрузите новый документ на платформу"
      />
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFormSubmit}
        onFinishFailed={() => {}}
        autoComplete="off"
        style={{ maxWidth: 600, margin: "50px auto" }}
        labelAlign="left"
      >
        <Form.Item
          label="Название"
          name="name"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          extra="Если документ относится к другому созданному документу, выберите его здесь"
          label="Подвязанный документ"
          name="parent_id"
        >
          <Select
            allowClear
            options={docs.map((doc) => {
              return {
                label: `${doc.name} (от ${moment(doc.createdAt).format(
                  "MM.DD.YYYY"
                )})`,
                value: doc.id,
              };
            })}
          />
        </Form.Item>

        <Form.Item
          extra="Выберите тип документа (файл или внешняя ссылка)"
          label="Тип документа"
          name="type"
          allowClear={false}
          initialValue={docType}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Select onChange={setDocType}>
            <Select.Option value={1}>Файл</Select.Option>
            <Select.Option value={2}>Ссылка</Select.Option>
          </Select>
        </Form.Item>

        <Divider />

        <Form.Item
          name="document"
          label={docType === 1 ? "Выберите файл" : "Введите ссылку"}
          extra={
            docType === 1
              ? "Загрузите документ (макс. 20 МБ)"
              : "Введите ссылку на внешний документ"
          }
          rules={[
            {
              required: true,
              message: "Это поле обязательно к заполнению",
            },
          ]}
        >
          {docType === 1 ? (
            <Upload beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />}>Выбрать файл</Button>
            </Upload>
          ) : (
            <Input type="url" />
          )}
        </Form.Item>

        <Divider />

        <Form.Item className="mt-4">
          <Button
            loading={isLoading}
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
          >
            Отправить
          </Button>
        </Form.Item>
      </Form>
    </Layouts.ArtistLayout>
  );
}
