import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function ProtectedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.signedIn && Component) {
          return <Component {...props} {...rest} />;
        }
        return <Redirect to="/signin" />;
      }}
    />
  );
}
