import axios from "axios";
import config from "@config";

const API = `${config.api}/documents`;
const API_ADMIN = `${config.api}/admin/documents`;

// Возвращает все документы (в соответствии с передаваемыми данными params)
export const getDocs = (params) =>
  axios
    .get(`${API_ADMIN}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает входящие документы
export const getIncomingDocs = (params) =>
  axios
    .get(`${API}/incoming`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает исходящие документы
export const getOutgoingDocs = (params) =>
  axios
    .get(`${API}/outgoing`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает входящие документы модератора
export const getAdminIncomingDocs = (params) =>
  axios
    .get(`${API_ADMIN}/incoming`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает исходящие документы модератора
export const getAdminOutgoingDocs = (params) =>
  axios
    .get(`${API_ADMIN}/outgoing`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает входящие документы определенного пользователя
export const getUserIncomingDocs = (params) =>
  axios
    .get(`${API_ADMIN}/incoming/user/id/${params.id}`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает исходящие документы определенного пользователя
export const getUserOutgoingDocs = (params) =>
  axios
    .get(`${API_ADMIN}/outgoing/user/id/${params.id}`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Создает новый документ
export const createDoc = (data) =>
  axios
    .post(`${API_ADMIN}/create`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Обновляет документ
export const updateDoc = (data) =>
  axios
    .post(`${API_ADMIN}/update/id/${data.id}`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает документ
export const getDoc = ({ id }) =>
  axios
    .get(`${API_ADMIN}/id/${id}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
