import { Layouts } from "@components";
import { ClockCircleOutlined } from "@ant-design/icons";

export default function ArtistPendingPage() {
  return (
    <Layouts.ArtistLayout disableInteraction>
      <div className="pending-page">
        <div className="row">
          <div className="col-12 col-lg-4 offset-lg-2 d-flex align-items-center">
            <img src="/img/pending-illustration.svg" />
          </div>
          <div className="col-12 col-lg-4 d-flex align-items-center">
            <div>
              <ClockCircleOutlined className="pending-icon" />
              <h1>Заявка на рассмотрении</h1>
              <p>
                Благодарим вас за регистрацию. Мы проверяем данные, чтобы вы как
                можно скорее начали пользоваться платформой. Мы сообщим как
                только ваша заявка будет одобрена.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layouts.ArtistLayout>
  );
}
