import { Layouts, Loading, Player } from "@components";
import {
  Form,
  Input,
  Button,
  Upload,
  Divider,
  Select,
  message,
  Tag,
  Menu,
  Dropdown,
  Modal,
  Image,
} from "antd";
import { useParams } from "react-router-dom";
import { UploadOutlined, SaveOutlined, RightOutlined } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import { PageHeader } from "antd";
import config from "@config";
import * as API from "@api";
import * as R from "ramda";

export default function ArtistAlbumEdit() {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tracks, setTracks] = useState([]);
  const [album, setAlbum] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    // Подгружаем данные
    async function fetchInitData() {
      const _res = {
        tracks: await API.Tracks.getTracks(),
        album: await API.Albums.getAlbum({ id }),
      };

      if (_res.album.error || _res.tracks.error) {
        return message.error("Невозможно загрузить альбом / треки", 2);
      }

      _res.album.data.tracks.map((track, i) => {
        return {
          value: track.id,
          label: track.name,
        };
      });

      for (let i = 0; i < _res.album.data.tracks.length; i++) {
        _res.album.data.tracks[i] = _res.album.data.tracks[i].id;
      }

      setAlbum(_res.album.data);
      setTracks(_res.tracks.data);
      setIsLoading(false);
      formRef.current.setFieldsValue(_res.album.data);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    // Формируем данные формы для отправки
    setIsLoading(true);
    const formData = new FormData();
    formData.append("id", album.id);
    formData.append("name", data.name);
    formData.append("name_en", data.name_en);
    formData.append("type", data.type);
    formData.append("tracks", data.tracks ? JSON.stringify(data.tracks) : []);

    // Изображение
    if (data.image) {
      formData.append("image", data.image);
    }

    const _res = await API.Albums.updateAlbum({ id: album.id, data: formData });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Не удалось обновить альбом", 2);
    }

    message.success("Альбом обновлен", 1, () => document.location.reload());
  };

  const handleMenuClick = async (btnId) => {
    Modal.confirm({
      title: "Обновить статус альбома",
      content:
        "Вы собираетесь обносить статус альбома. Пожалуйста, подтвердите.",
      onOk: async () => {
        formRef.current.submit();
        const status = btnId.key;

        setIsLoading(true);
        const _res = await API.Albums.updateStatus({
          id: album.id,
          status,
        });
        setIsLoading(false);

        if (_res.error) {
          return message.error("Не удалось обновить статус альбома", 2);
        }

        message.success(
          "Статус альбома обновлен",
          1,
          document.location.reload()
        );
      },
      okText: "Подтвердить",
    });
  };

  const albumtypes = [
    { name: "Студийный альбом", id: 1 },
    { name: "EP (Extended Play)", id: 2 },
    { name: "Сборник (Compilation Album)", id: 3 },
    { name: "Концепт (Concept Album)", id: 4 },
    { name: "Live", id: 5 },
    { name: "Двойной (Double Album)", id: 6 },
    { name: "Хиты (Greatest Hits Album)", id: 7 },
    { name: "Ремиксы (Remix Album)", id: 8 },
    { name: "Дебютный альбом", id: 9 },
    { name: "Соло", id: 10 },
    { name: "Другое", id: 11 },
  ];

  // Статус трека
  const getStatus = (id) => {
    switch (id) {
      case "new":
        return <Tag>Загружен</Tag>;

      case "pending":
        return <Tag color="orange">Отправлен на проверку</Tag>;

      case "rejected":
        return <Tag color="red">Отклонен модератором</Tag>;

      case "published":
        return <Tag color="success">Альбом опубликован</Tag>;

      case "awaiting_artist":
        return <Tag color="orange">Ожидает вашего действия</Tag>;

      case "removed":
        return <Tag color="red">Удален</Tag>;

      default:
        return <Tag>Загружен</Tag>;
    }
  };

  const form = (
    <Form
      name="album"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFormSubmit}
      onFinishFailed={() => {}}
      ref={formRef}
      style={{ maxWidth: 500, margin: "20px auto" }}
      labelAlign="left"
    >
      <Form.Item name="id" hidden>
        <Input htmlType="hidden" disabled />
      </Form.Item>

      <Form.Item label="Изображение" name="image">
        {album?.image ? (
          <Image
            style={{ width: "100%" }}
            src={`${config.paths.s3}/${album.image}`}
          />
        ) : (
          "Нет изображения"
        )}
      </Form.Item>

      <Form.Item label="Статус" name="status">
        {getStatus(R.pathOr(null, ["status"], album))}
      </Form.Item>

      {R.pathOr(null, ["upc"], album) && (
        <Form.Item label="UPC" name="upc">
          {album?.upc}
        </Form.Item>
      )}

      <Form.Item
        label="Название"
        name="name"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Название на англ."
        name="name_en"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        extra="Выберите тип альбома"
        label="Тип альбома"
        name="type"
        allowClear={false}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Select
          options={albumtypes.map((type) => {
            return { label: type.name, value: type.id };
          })}
        />
      </Form.Item>

      <Form.Item
        extra="Выберите треки альбома"
        label="Треки"
        name="tracks"
        rules={[
          {
            required: true,
            message: "Выберите треки для альбома",
          },
        ]}
      >
        <Select
          allowClear
          mode="multiple"
          optionFilterProp="children"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={tracks.map((track) => {
            return {
              label: track.name,
              value: track.id,
            };
          })}
        />
      </Form.Item>

      <Divider />

      <Form.Item
        name="image"
        label="Изображение"
        extra="Загрузите, чтобы заменить текущее изображение"
      >
        <Upload
          beforeUpload={() => false}
          accept="image/*"
          name="image"
          listType="picture"
        >
          <Button icon={<UploadOutlined />}>Выбрать изображение</Button>
        </Upload>
      </Form.Item>

      <Divider />

      <Form.Item className="mt-4" noStyle>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            loading={isLoading}
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
            disabled={
              album?.status === "pending" ||
              album?.status === "awaiting_artst" ||
              album?.status === "published" ||
              album?.status === "removed"
            }
          >
            Сохранить
          </Button>
          <Dropdown.Button
            onClick={() =>
              handleMenuClick({
                key: "pending",
              })
            }
            disabled={
              album?.status === "pending" || album?.status === "removed"
            }
            overlay={
              <Menu onClick={handleMenuClick}>
                <Menu.Item key="pending">Отправить на модерацию</Menu.Item>
                <Menu.Item key="removed">Снять с ротации</Menu.Item>
              </Menu>
            }
          >
            На модерацию <RightOutlined />
          </Dropdown.Button>
        </div>
      </Form.Item>
    </Form>
  );

  return (
    <Layouts.ArtistLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Обновить альбом"
        subTitle="Обновите информациб об альбоме"
      />
      {isLoading ? <Loading /> : form}
    </Layouts.ArtistLayout>
  );
}
