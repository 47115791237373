import { Layouts } from "@components";
import { Button, Table, message, Tag, Divider, Input } from "antd";
import { SearchOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PageHeader } from "antd";
import moment from "moment";
import * as API from "@api/admin";
import config from "@config";

export default function Reports() {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  const onSearch = async (s) => {
    setPagination(
      {
        ...pagination,
        current: 1,
      },
      setSearch(s)
    );
  };

  const getReports = async (p) => {
    setIsLoading(true);

    const _res = await API.Reports.getReports({
      s: search || null,
      limit: p.pageSize,
      offset: p.current * p.pageSize - p.pageSize,
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Невозможно загрузить данные", 2);
    }

    setTableData(
      _res.data,
      setPagination({
        ...pagination,
        ...p,
      })
    );
  };

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Reports.getReports({
        s: search || null,
        limit: pagination.pageSize,
        offset: pagination.current * pagination.pageSize - pagination.pageSize,
      });

      setIsLoading(false);

      if (_res.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      setTableData(
        _res.data,
        setPagination({
          ...pagination,
          total: _res.pagination.total,
        })
      );
    }

    fetchInitData();
  }, [search]);

  const getStatus = (id) => {
    switch (id) {
      case "new":
        return (
          <Tag style={{ margin: 0 }} color="blue">
            Новый
          </Tag>
        );

      case "opened":
        return <Tag style={{ margin: 0 }}>Посмотрен</Tag>;

      case "rejected":
        return (
          <Tag style={{ margin: 0 }} color="red">
            Отклонен
          </Tag>
        );

      default:
        return <Tag style={{ margin: 0 }}>Загружен</Tag>;
    }
  };

  const columns = [
    {
      title: "Артист",
      dataIndex: "artist",
      key: "artist",
      render: (data) => (
        <Link to={`/artists/view/${data.id}`}>{data.name}</Link>
      ),
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (data) => <h4 className="m-0">{data}</h4>,
    },
    {
      title: "Период отчета",
      dataIndex: "period",
      key: "period",
      align: "center",
      render: (_, all) => (
        <div className="row">
          <div className="col-12" style={{ textTransform: "capitalize" }}>
            {moment(all.period_start).locale("ru").format("MMMM YYYY")} —{" "}
            {moment(all.period_end).locale("ru").format("MMMM YYYY")}
          </div>
        </div>
      ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (data) => getStatus(data),
    },
    {
      title: "Подпись",
      dataIndex: "requires_artist_signature",
      key: "requires_artist_signature",
      align: "center",
      render: (data, all) => {
        if (!data) {
          return <Tag style={{ margin: 0 }}>Не требуется</Tag>;
        }

        if (data && all.artist_signed) {
          return (
            <Tag style={{ margin: 0 }} color="green">
              Документ подписан
            </Tag>
          );
        }

        return (
          <Tag style={{ margin: 0 }} color="blue">
            Ожидание артиста
          </Tag>
        );
      },
    },
    {
      title: "Посл. изменение",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (data) => (
        <p className="m-0">{moment(data).format("HH:mm DD.MM.YYYY")}</p>
      ),
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (_, all) => (
        <Link to={`/reports/edit/${all.id}`}>
          <Button>Открыть</Button>
        </Link>
      ),
    },
  ];

  return (
    <Layouts.AdminLayout className="tracks-page">
      <PageHeader
        className="site-page-header"
        title="Отчеты"
        subTitle="Управляйте отчетами артистов"
      />
      <Divider />
      <Input.Search
        prefix={<SearchOutlined />}
        placeholder="Поиск по названию"
        enterButton="Найти"
        allowClear
        onSearch={onSearch}
      />
      <Divider />
      <Table
        onChange={getReports}
        className="tracks-table"
        pagination={pagination}
        dataSource={tableData || []}
        columns={columns}
        loading={isLoading}
      />
    </Layouts.AdminLayout>
  );
}
