import { Layouts, Loading } from "@components";
import {
  Form,
  Input,
  Button,
  Upload,
  Divider,
  Select,
  message,
  DatePicker,
  PageHeader,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import * as API from "@api/admin";
import * as R from "ramda";
import DocSelector from "./docselector";

export default function NewRerort() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [artist, setArtist] = useState(null);
  const [reportType, setReportType] = useState(2);
  const [relatedDocId, setRelatedDocId] = useState(null);
  const [docModalVisible, setDocModalVisible] = useState(false);
  const router = useHistory();

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Artists.getArtist({ id });

      if (_res.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      setArtist(_res.data);
      setIsLoading(false);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("user_id", artist.user_id);
    formData.append("artist_id", artist.id);
    formData.append(
      "period_start",
      data.period_start.set("date", 1).format("YYYY-MM-DD")
    );
    formData.append(
      "period_end",
      data.period_end.set("date", 1).format("YYYY-MM-DD")
    );
    formData.append("type", data.type);
    formData.append("status", "new");
    formData.append(
      "requires_artist_signature",
      data.requires_artist_signature
    );
    formData.append(
      "requires_moderator_signature",
      data.requires_moderator_signature
    );

    if (data.description) {
      formData.append("description", data.description);
    }

    if (relatedDocId) {
      formData.append("related_document_id", relatedDocId);
    }

    if (data.type === 2) {
      const file = R.pathOr(null, ["reportfile", "file"], data);

      if (file) {
        formData.append("reportfile", file);
      } else {
        setIsLoading(false);
        return message.error("Пожалуйста, загрузите файл отчета", 1);
      }
    } else {
      formData.append("url", data.url);
    }

    const _res = await API.Reports.createReport(formData);

    setIsLoading(false);

    if (_res.error) {
      return message.error("Не удалось создать отчет", 2);
    }

    message.success("Отчет создан", 1, () => router.push("/reports"));
  };

  if (isLoading) {
    return (
      <Layouts.AdminLayout>
        <Loading />
      </Layouts.AdminLayout>
    );
  }

  return (
    <Layouts.AdminLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Новый отчет"
        subTitle="Загрузите новый отчет артиста на платформу"
      />
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFormSubmit}
        onFinishFailed={() => {}}
        autoComplete="off"
        style={{ maxWidth: 600, margin: "50px auto" }}
        labelAlign="left"
      >
        <Form.Item
          name="artist_id"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
          hidden
          initialValue={artist?.user_id}
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Артист"
          name="artist"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
          initialValue={artist.name}
          disabled
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Название"
          name="name"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input placeholder="Напр. Отчет за период 01.2020 - 04.2020" />
        </Form.Item>

        <Form.Item label="Краткое описание" name="description">
          <Input.TextArea rows={3} placeholder="Небольшое описание отчета" />
        </Form.Item>

        <Form.Item
          label="Отчетный период (начало)"
          name="period_start"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <DatePicker
            picker="month"
            format="MM.YYYY"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="Отчетный период (конец)"
          name="period_end"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <DatePicker
            picker="month"
            format="MM.YYYY"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          extra="Если отчет имеет подвязку к документу (напр. инвойс), прикрепите документ здесь"
          label="Подвязанный документ"
          name="related_document_id"
        >
          {relatedDocId ? (
            <Button danger type="primary" onClick={() => setRelatedDocId(null)}>
              Отвязать документ
            </Button>
          ) : (
            <Button onClick={() => setDocModalVisible(true)}>
              Выбрать документ
            </Button>
          )}
        </Form.Item>

        <Form.Item
          label="Тип отчета"
          name="type"
          allowClear={false}
          initialValue={reportType}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Select onChange={setReportType}>
            <Select.Option value={1} disabled>
              Автоматически сгенерированный отчет (недоступен)
            </Select.Option>
            <Select.Option value={2}>PDF-файл отчета</Select.Option>
            <Select.Option value={3}>
              Внешняя ссылка на отчет (напр. Google Drive)
            </Select.Option>
          </Select>
        </Form.Item>

        <Divider />

        {reportType === 2 && (
          <Form.Item
            name="reportfile"
            label="Файл отчета"
            extra="Загрузите PDF-документ (макс. 20 МБ)"
            rules={[
              {
                validator: (_, value) => {
                  if (value.fileList.length > 0) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error("Это поле обязательно к заполнению")
                  );
                },
                required: true,
                message: "Это поле обязательно к заполнению",
              },
            ]}
          >
            <Upload
              accept="application/pdf"
              beforeUpload={() => false}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Выбрать файл</Button>
            </Upload>
          </Form.Item>
        )}

        {reportType === 3 && (
          <Form.Item
            name="url"
            label="Ссылка на отчет"
            extra="Введите ссылку на внешний документ"
            rules={[
              {
                required: true,
                message: "Это поле обязательно к заполнению",
              },
            ]}
          >
            <Input type="url" />
          </Form.Item>
        )}

        <Divider />

        <Form.Item
          label="Подпись артиста"
          name="requires_artist_signature"
          allowClear={false}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
          initialValue={1}
        >
          <Select>
            <Select.Option value={1}>Подпись артиста обязательна</Select.Option>
            <Select.Option value={0}>
              Подпись артиста не требуется
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Подпись модератора"
          name="requires_moderator_signature"
          allowClear={false}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
          initialValue={1}
        >
          <Select>
            <Select.Option value={1}>
              Подпись модератора обязательна
            </Select.Option>
            <Select.Option value={0}>
              Подпись модератора не требуется
            </Select.Option>
          </Select>
        </Form.Item>

        <Divider />

        <Form.Item className="mt-4">
          <Button
            loading={isLoading}
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
          >
            Отправить
          </Button>
        </Form.Item>
      </Form>
      <DocSelector
        onDocModalClose={() => setDocModalVisible(false)}
        onDocSelected={({ id }) =>
          setRelatedDocId(id, setDocModalVisible(false))
        }
        visible={docModalVisible}
        user_id={artist?.user_id}
      />
    </Layouts.AdminLayout>
  );
}
