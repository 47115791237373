import { Layouts, DocModal } from "@components";
import { Button, Table, message, Tag, Tabs, Badge, PageHeader } from "antd";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import * as API from "@api/admin";
import * as R from "ramda";
import "moment/locale/ru";

export default function OutgoingDocs() {
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [doc, setDoc] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Docs.getAdminOutgoingDocs({
        limit: pagination.pageSize,
        offset: pagination.current * pagination.pageSize - pagination.pageSize,
      });

      setIsLoading(false);

      if (_res.error) {
        return message.error("Невозможно загрузить документы", 2);
      }

      setTableData(
        _res.data,
        setPagination({
          ...pagination,
          total: _res.pagination.total,
        })
      );
    }

    fetchInitData();
  }, []);

  const getOutgoingDocs = async (p) => {
    setIsLoading(true);

    const _res = await API.Reports.getAdminOutgoingDocs({
      limit: p.pageSize,
      offset: p.current * p.pageSize - p.pageSize,
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Невозможно загрузить данные", 2);
    }

    setTableData(
      _res.data,
      setPagination({
        ...pagination,
        ...p,
      })
    );
  };

  const getStatus = (id) => {
    switch (id) {
      case "new":
        return (
          <Tag style={{ margin: 0 }} color="blue">
            Новый
          </Tag>
        );

      case "opened":
        return <Tag style={{ margin: 0 }}>Просмотрен</Tag>;

      case "rejected":
        return (
          <Tag color="red" style={{ margin: 0 }}>
            Отклонен
          </Tag>
        );

      case "accepted":
        return (
          <Tag color="green" style={{ margin: 0 }}>
            Принят
          </Tag>
        );

      default:
        return <Tag style={{ margin: 0 }}>Загружен</Tag>;
    }
  };

  const onDocOpen = async ({ id }) => {
    const doc = await API.Docs.getDoc({ id });

    if (doc.error) {
      return message.error("Невозможно открыть документ", 2);
    }

    setDoc(doc.data, setModalVisible(true));
  };

  const onDocClose = (doc) => {
    setDoc(null, setModalVisible(false));
  };

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (data) => <h4 className="m-0">{data}</h4>,
    },
    {
      title: "Получатель",
      dataIndex: "user",
      key: "user",
      render: (data, all) => (
        <div>
          <a href={`/users/edit/${all.target_user_id}`}>
            {all.target_user.first_name} {all.target_user.last_name}
          </a>{" "}
          (
          <a href={`/artists/view/${all.target_user.artist.id}`}>
            {all.target_user.artist.name}
          </a>
          )
        </div>
      ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (data) => getStatus(data),
    },
    {
      title: "Подвязанный документ",
      dataIndex: "parent_id",
      key: "parent_id",
      align: "center",
      render: (data, all) =>
        data ? (
          <Link to={`/docs/edit/${all.parent.id}`}>
            <a>{all.parent.name}</a>
          </Link>
        ) : (
          <Tag>Нет</Tag>
        ),
    },
    {
      title: "Посл. изменение",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (data) => (
        <p className="m-0">{moment(data).format("HH:mm DD.MM.YYYY")}</p>
      ),
    },
    {
      dataIndex: "actions",
      key: "actions",
      render: (_, all) => (
        <Link to={`/docs/edit/${all.id}`}>
          <Button>Открыть</Button>
        </Link>
      ),
    },
  ];

  return (
    <div>
      <Table
        key="incoming"
        className="tracks-table"
        onChange={getOutgoingDocs}
        pagination={pagination}
        dataSource={tableData || []}
        columns={columns}
        loading={isLoading}
      />

      <DocModal doc={doc} visible={modalVisible} onClose={onDocClose} />
    </div>
  );
}
