import axios from "axios";
import config from "@config";

const API = `${config.api}/admin/reports`;

// Возвращает все отчеты
export const getReports = (params) =>
  axios
    .get(API, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает отчет
export const getReport = (params) =>
  axios
    .get(`${API}/id/${params.id}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Создает новый отчет
export const createReport = (data) =>
  axios
    .post(`${API}/create`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Обновляет отчет
export const updateReport = (data) =>
  axios
    .post(`${API}/update/id/${data.id}`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
