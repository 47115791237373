import { Fragment } from "react";
import ArtistRoutes from "./artist";
import AdminRoutes from "./admin";
import { ArtistRegistration, ArtistPending } from "@pages";
import { ProtectedRoute } from "@components";
import { pathOr } from "ramda";

export default function RoutesHandler(params) {
  const { user, hasArtist } = params;

  if (pathOr(null, ["role"], user) === 2) {
    return <AdminRoutes {...params} />;
  }

  if (!hasArtist) {
    return (
      <Fragment>
        <ProtectedRoute
          exact
          path="/"
          {...params}
          component={ArtistRegistration}
        />
        <ProtectedRoute
          exact
          path="/pending"
          {...params}
          component={ArtistPending}
        />
      </Fragment>
    );
  }

  return <ArtistRoutes {...params} />;
}
