import { Modal } from "antd";

export default function GuideModal(params) {
  return (
    <Modal
      title="Инструкции для исполнителей"
      visible={params.visible}
      onCancel={params.onClose}
      width={800}
      centered
      closable
      maskClosable
      footer={null}
      destroyOnClose
    >
      <div className="guide-modal-container">
        <div className="embed-container">
          <iframe
            src="https://www.youtube.com/embed/kwqdEOHjAGY"
            title="Инструкции для исполнителей"
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
    </Modal>
  );
}
