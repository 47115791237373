import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PDFViewer, Loading } from "@components";
import { Helmet } from "react-helmet";
import { message } from "antd";
import * as API from "@api";

export default function ReportSingle(params) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState(null);
  const [artist, setArtist] = useState(null);

  useEffect(() => {
    // Подгружаем данные трека
    async function fetchInitData() {
      const _res = {
        report: await API.Reports.getReport({ id }),
        artist: await API.Artists.getArtist(),
      };

      if (_res.report.error || _res.artist.error) {
        return message.error("Невозможно загрузить данные с сервера", 2);
      }

      setIsLoading(false);
      setReport(_res.report.data);
      setArtist(_res.artist.data);
    }

    fetchInitData();
  }, []);

  if (isLoading || !report || !artist) {
    return <Loading />;
  }

  return <PDFViewer report={report} artist={artist} />;
}
