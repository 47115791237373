import { Layouts } from "@components";
import { Button, Table, message, Tag, Divider, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PageHeader } from "antd";
import moment from "moment";
import * as API from "@api/admin";
import config from "@config";

export default function Artists() {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  const onSearch = async (s) => {
    setPagination(
      {
        ...pagination,
        current: 1,
      },
      setSearch(s)
    );
  };

  const getArtists = async (p) => {
    setIsLoading(true);

    const _res = await API.Artists.getArtists({
      s: search || null,
      limit: p.pageSize,
      offset: p.current * p.pageSize - p.pageSize,
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Невозможно загрузить данные", 2);
    }

    setTableData(
      _res.data,
      setPagination({
        ...pagination,
        ...p,
      })
    );
  };

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Artists.getArtists({
        s: search || null,
        limit: pagination.pageSize,
        offset: pagination.current * pagination.pageSize - pagination.pageSize,
      });

      setIsLoading(false);

      if (_res.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      setTableData(
        _res.data,
        setPagination({
          ...pagination,
          total: _res.pagination.total,
        })
      );
    }

    fetchInitData();
  }, [search]);

  const columns = [
    {
      dataIndex: "photo",
      key: "photo",
      align: "center",
      render: (data) => (
        <img
          src={data ? `${config.paths.s3}/${data}` : "/img/icon-track.svg"}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/img/icon-track.svg";
          }}
          className="track-img"
          alt="Изображение артиста"
        />
      ),
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (data) => <h4 className="m-0">{data}</h4>,
    },
    {
      title: "Представитель",
      dataIndex: "user",
      key: "user",
      align: "center",
      render: (data) => (
        <Link to={`/users/view/${data.id}`}>
          {data.first_name} {data.last_name}
        </Link>
      ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (data) =>
        data === "active" ? (
          <Tag color="green" style={{ margin: 0 }}>
            Активный
          </Tag>
        ) : (
          <Tag color="red" style={{ margin: 0 }}>
            Неактивный
          </Tag>
        ),
    },
    {
      title: "Посл. изменение",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (data) => (
        <p className="m-0">{moment(data).format("HH:mm DD.MM.YYYY")}</p>
      ),
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (data, all) => (
        <Link to={`/artists/view/${all.id}`}>
          <Button type="primary">Открыть</Button>
        </Link>
      ),
    },
  ];

  return (
    <Layouts.AdminLayout className="tracks-page">
      <PageHeader
        className="site-page-header"
        title="Артисты"
        subTitle="Все зарегистрированные на платформе артисты"
      />
      <Divider />
      <Input.Search
        prefix={<SearchOutlined />}
        placeholder="Поиск по названию"
        enterButton="Найти"
        allowClear
        onSearch={onSearch}
      />
      <Divider />
      <Table
        onChange={getArtists}
        className="tracks-table"
        pagination={pagination}
        dataSource={tableData || []}
        columns={columns}
        loading={isLoading}
      />
    </Layouts.AdminLayout>
  );
}
