import { Button, Table, message, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import * as API from "@api/admin";
import config from "@config";

export default function ArtistAlbums(params) {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Tracks.getTracks({
        artist_id: params.artist_id,
        limit: pagination.pageSize,
        offset: pagination.current * pagination.pageSize - pagination.pageSize,
      });

      setIsLoading(false);

      if (_res.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      setTableData(
        _res.data,
        setPagination({
          ...pagination,
          total: _res.pagination.total,
        })
      );
    }

    fetchInitData();
  }, []);

  const getTracks = async (p) => {
    setIsLoading(true);

    const _res = await API.Tracks.getTracks({
      artist_id: params.artist_id,
      limit: p.pageSize,
      offset: p.current * p.pageSize - p.pageSize,
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Невозможно загрузить данные", 2);
    }

    setTableData(
      _res.data,
      setPagination({
        ...pagination,
        ...p,
      })
    );
  };

  const getStatus = (id) => {
    switch (id) {
      case "new":
        return <Tag>Загружен</Tag>;

      case "pending":
        return <Tag color="orange">Ожидает проверки</Tag>;

      case "rejected":
        return <Tag color="red">Отклонен модератором</Tag>;

      case "published":
        return <Tag color="success">Альбом опубликован</Tag>;

      case "awaiting_artist":
        return <Tag color="orange">Ожидает артиста</Tag>;

      case "removed":
        return <Tag color="red">Удален</Tag>;

      default:
        return <Tag>Загружен</Tag>;
    }
  };

  const columns = [
    {
      dataIndex: "image",
      key: "image",
      render: (data) => (
        <img
          src={data ? `${config.paths.s3}/${data}` : "/img/icon-track.svg"}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/img/icon-track.svg";
          }}
          className="track-img"
          style={{ maxWidth: 100 }}
          alt="Изображение трека"
        />
      ),
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (data) => <h4 className="m-0">{data}</h4>,
    },
    {
      title: "Альбом",
      dataIndex: "album_id",
      key: "album_id",
      render: (data, all) =>
        data && (
          <Link to={`/albums/edit/${data}`}>
            <a>{all.album.name}</a>
          </Link>
        ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (data) => getStatus(data),
    },
    {
      title: "Посл. изменение",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (data) => (
        <p className="m-0">{moment(data).format("HH:mm DD.MM.YYYY")}</p>
      ),
    },
    {
      dataIndex: "actions",
      key: "actions",
      render: (data, all) => (
        <Link to={`/tracks/edit/${all.id}`}>
          <Button>Открыть</Button>
        </Link>
      ),
    },
  ];

  return (
    <Table
      className="tracks-table"
      pagination={pagination}
      onChange={getTracks}
      dataSource={tableData || []}
      columns={columns}
      loading={isLoading}
      title={() => (
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <h3 className="m-0">Треки</h3>
          <Link to="/tracks/new">
            <Button type="primary" icon={<PlusOutlined />}>
              Новый трек
            </Button>
          </Link>
        </div>
      )}
    />
  );
}
