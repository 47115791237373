import { Layouts, Loading, Player } from "@components";
import { Form, Input, Button, Upload, Divider, Select, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { PageHeader } from "antd";
import config from "@config";
import * as R from "ramda";
import * as API from "@api/admin";

export default function EditTrack() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [track, setTrack] = useState(null);
  const [albums, setAlbums] = useState([]);
  const router = useHistory();

  useEffect(() => {
    async function fetchInitData() {
      let _res = {
        track: await API.Tracks.getTrack({ id }),
      };

      _res.albums = await API.Albums.getAlbums({
        artist_id: _res.track.data.artist_id,
      });

      if (_res.albums.error || _res.track.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      setAlbums(_res.albums.data);
      setTrack(_res.track.data);
      setIsLoading(false);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("name_en", data.name_en);
    formData.append("meta", JSON.stringify(data.meta || []));
    formData.append("upc", data.upc);
    formData.append("isrc", data.isrc);
    formData.append("status", data.status);
    formData.append("album_id", data.album_id || null);

    if (R.pathOr(null, ["audio", "file"], data)) {
      formData.append("audio", data.audio.file);
    }

    if (R.pathOr(null, ["image", "file"], data)) {
      formData.append("image", data.image.file);
    }

    const _res = await API.Tracks.updateTrack({ id: track.id, data: formData });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Не удалось обновить трек", 2);
    }

    message.success("Трек обновлен", 1, () => document.location.reload());
  };

  if (isLoading) {
    return (
      <Layouts.AdminLayout>
        <Loading />
      </Layouts.AdminLayout>
    );
  }

  return (
    <Layouts.AdminLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Трек артиста"
        subTitle="Редактируйте информацию о треке артиста"
      />
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFormSubmit}
        onFinishFailed={() => {}}
        autoComplete="off"
        style={{ maxWidth: 500, margin: "50px auto" }}
        labelAlign="left"
        initialValues={track}
      >
        <Player
          url={`${config.paths.s3}/${track.url}`}
          title={track.name}
          subtitle={track.artist.name}
          image={`${config.paths.s3}/${track.image}`}
        />
        <Divider />
        <Form.Item
          label="Артист"
          name={["artist", "name"]}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Название"
          name="name"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Название на англ." name="name_en">
          <Input />
        </Form.Item>

        <Form.Item
          extra="Выберите принадлежность к альбому, если необходимо"
          label="Альбом"
          name="album_id"
        >
          <Select
            allowClear
            options={albums.map((album) => {
              return { label: album.name, value: album.id };
            })}
          />
        </Form.Item>

        <Divider />

        <Form.Item label="UPC" name="upc">
          <Input />
        </Form.Item>

        <Form.Item label="ISRC" name="isrc">
          <Input />
        </Form.Item>

        <Form.Item label="Текст песни" name={["meta", "lyrics"]}>
          <Input.TextArea rows={5} />
        </Form.Item>

        <Divider />

        <Form.Item label="Статус" name="status">
          <Select allowClear={false}>
            <Select.Option value="new">Новый</Select.Option>
            <Select.Option value="pending">Ожидает модерации</Select.Option>
            <Select.Option value="rejected">Отклонен</Select.Option>
            <Select.Option value="awaiting_artist">
              Ожидает артиста
            </Select.Option>
            <Select.Option value="published">Опубликован</Select.Option>
            <Select.Option value="opened">Просмотрен</Select.Option>
            <Select.Option value="removed">Снят с ротации</Select.Option>
          </Select>
        </Form.Item>

        <Divider />

        <Form.Item
          name="image"
          label="Изображение"
          extra="Выберите новое изображение (макс. 5 МБ)"
        >
          <Upload
            beforeUpload={() => false}
            accept="image/*"
            name="image"
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Выбрать изображение</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="audio"
          label="Файл трека"
          extra="Выберите новый файл трека (макс. 15 МБ)"
        >
          <Upload beforeUpload={() => false} accept=".mp3,audio/*" name="audio">
            <Button icon={<UploadOutlined />}>Выбрать аудиофайл</Button>
          </Upload>
        </Form.Item>

        <Divider />

        <Form.Item className="mt-4">
          <Button
            loading={isLoading}
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Layouts.AdminLayout>
  );
}
