import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Layouts, Loading } from "@components";
import Reports from "./reports";
import IncomingDocs from "./incomingdocs";
import OutgoingDocs from "./outgoingdocs";
import Albums from "./albums";
import Tracks from "./tracks";
import { message, Tabs, PageHeader, Descriptions, Button, Divider } from "antd";
import config from "@config";
import * as API from "@api/admin";
import moment from "moment";

export default function ViewArtist(params) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [artist, setArtist] = useState(null);

  useEffect(() => {
    // Подгружаем данные трека
    async function fetchInitData() {
      let _res = await API.Artists.getArtist({ id });

      if (_res.error) {
        return message.error("Невозможно загрузить данные с сервера", 2);
      }

      setArtist(_res.data);
      setIsLoading(false);
    }

    fetchInitData();
  }, []);

  if (isLoading) {
    return (
      <Layouts.AdminLayout>
        <Loading />
      </Layouts.AdminLayout>
    );
  }

  return (
    <Layouts.AdminLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title={artist?.name || "Артист"}
        subTitle="Информация об артисте"
      />
      <Descriptions
        className="description-page"
        layout="vertical"
        title="Основная информация"
        extra={[
          <Link to={`/artists/edit/${artist.id}`}>
            <Button type="primary">Редактировать</Button>
          </Link>,
        ]}
      >
        <Descriptions.Item label="Название">{artist.name}</Descriptions.Item>
        <Descriptions.Item label="Представитель">
          <Link to={`/users/view/${artist.user.id}`}>
            {artist.user.first_name} {artist.user.last_name}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Описание">
          {artist.description}
        </Descriptions.Item>
        <Descriptions.Item label="Статус">
          {artist.status === "active" ? "Активный" : "Неактивный"}
        </Descriptions.Item>
        <Descriptions.Item label="Дата регистрации">
          {moment(artist.createdAt).format("HH:mm MM.DD.YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="Фото">
          {artist.photo && (
            <a target="_blank" href={`${config.paths.s3}/${artist.photo}`}>
              Открыть
            </a>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Контракты">
          <ul>
            {artist?.user?.contracts?.map((contract) => (
              <li>
                <a target="_blank" href={`/contracts/view/${contract.id}`}>
                  {contract.template.name}
                </a>
              </li>
            ))}
          </ul>
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Tabs centered defaultActiveKey="reports">
        <Tabs.TabPane tab="Отчеты" key="reports">
          <Reports artist_id={artist.id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Документы (отпр.)" key="incoming_docs">
          <IncomingDocs user_id={artist.user.id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Документы (получ.)" key="outgoing_docs">
          <OutgoingDocs user_id={artist.user.id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Альбомы" key="albums">
          <Albums artist_id={artist.id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Треки" key="tracks">
          <Tracks artist_id={artist.id} />
        </Tabs.TabPane>
      </Tabs>
    </Layouts.AdminLayout>
  );
}
