import React, { useLayoutEffect, useRef, useState } from "react";
import config from "@config";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

export default function ArtistReports(params) {
  const [numPages, setNumPages] = useState(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const pageRef = useRef(null);

  useLayoutEffect(() => {
    if (pageRef.current && !width && !height) {
      setWidth(pageRef.current.clientWidth);
      setHeight(pageRef.current.clientHeight);
    }
  }, [pageRef.current]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function getSignatureBox() {
    const { report, artist } = params;
    const { user } = artist;

    if (report.requires_artist_signature) {
      return (
        <div className="signature-box">
          <div className="signature-cell">Gakku Media: </div>
          <div className="signature-cell">
            {`${user.first_name} ${user.last_name}`}:
            {report.artist_signed && (
              <img
                className="signature"
                src={`${config.paths.s3}/${user.signature}`}
                alt="Подпись артиста"
              />
            )}
          </div>
        </div>
      );
    }

    return;
  }

  function pageRenderer() {
    return Array.from(new Array(numPages), (el, index) => (
      <div className="page-super">
        <Page
          inputRef={pageRef}
          height={height}
          width={width}
          pageIndex={index}
          className="page"
          renderAnnotationLayer={false}
          renderTextLayer={false}
        >
          {getSignatureBox()}
        </Page>
      </div>
    ));
  }

  return (
    <div className="doc">
      <Document
        file={`${config.paths.s3}/${params.report.url}`}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {pageRenderer()}
      </Document>
    </div>
  );
}
