import * as R from "ramda";
import ReactDOMServer from "react-dom/server";
import config from "@config";
import moment from "moment";
import { Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";

export default function ContractViewer({ contract }) {
  let template = contract.template;
  let data = template.content.data;

  // Inject contract number
  data = R.replace(
    /{{contract.number}}/g,
    `${R.pathOr("", ["metadata", "prefix"], template)}-${R.pathOr(
      contract.number,
      ["parent", "number"],
      contract
    )}`,
    data
  );

  // Inject signed date
  data = R.replace(
    /{{contract.signed_date}}/g,
    moment(
      R.pathOr(contract.signed_at, ["parent", "signed_at"], contract)
    ).format("DD.MM.YYYY"),
    data
  );

  // Inject date
  data = R.replace(
    /{{date}}/g,
    moment(contract.createdAt).format("DD.MM.YYYY"),
    data
  );

  // Inject full name
  data = R.replace(
    /{{user.full_name}}/g,
    `${contract.user.first_name} ${contract.user.last_name}`,
    data
  );

  // Inject IIN
  data = R.replace(
    /{{user.id.iin}}/g,
    `${R.pathOr("", ["user", "company", "id", "iin"], contract)}`,
    data
  );

  // Inject ID Number
  data = R.replace(
    /{{user.id.number}}/g,
    `${R.pathOr("", ["user", "company", "id", "number"], contract)}`,
    data
  );

  // Inject ID Issuer
  data = R.replace(
    /{{user.id.issuer}}/g,
    `${R.pathOr("", ["user", "company", "id", "issuer"], contract)}`,
    data
  );

  // Inject ID Issue Date
  data = R.replace(
    /{{user.id.issue_date}}/g,
    `${R.pathOr("", ["user", "company", "id", "issue_date"], contract)}`,
    data
  );

  // Inject user address
  data = R.replace(
    /{{user.address}}/g,
    `${R.pathOr("", ["user", "company", "address"], contract)}`,
    data
  );

  // Inject user tel
  data = R.replace(
    /{{user.tel}}/g,
    `+${R.pathOr("", ["user", "tel"], contract)}`,
    data
  );

  // Inject user email
  data = R.replace(
    /{{user.email}}/g,
    `${R.pathOr("", ["user", "email"], contract)}`,
    data
  );

  // Inject user company name
  data = R.replace(
    /{{user.company.name}}/g,
    `${R.pathOr("", ["user", "company", "name"], contract)}`,
    data
  );

  // Inject user authority
  data = R.replace(/{{user.authority}}/g, "Устава", data);

  // Inject artist name
  data = R.replace(
    /{{artist.name}}/g,
    `${R.pathOr("", ["artist", "name"], contract)}`,
    data
  );

  // Inject company address
  data = R.replace(
    /{{user.company.address}}/g,
    `${R.pathOr("", ["user", "company", "address"], contract)}`,
    data
  );

  data = R.replace(
    /{{user.signature_full}}/g,
    ReactDOMServer.renderToString(
      <div
        className="contract-signature"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <img
          src={`${config.paths.s3}/${contract.user.signature}`}
          alt="Подпись"
        />
        {contract.user.first_name} {contract.user.last_name}
      </div>
    ),
    data
  );

  return (
    <div className="contract-view">
      <div className="contract-button-row">
        <Button
          onClick={() => window.print()}
          icon={<PrinterOutlined />}
          type="primary"
          size="large"
        >
          Распечатать
        </Button>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `@page {
            size: A4;
            margin: 50px;
          } @media print {
            div, table {
              break-inside: avoid;
            }
            html, body {
              text-align: justify;
              width: 210mm;
              height: 297mm;
              font-size: 10px;
              font-family: Arial, sans-serif;
            }
          }`,
        }}
      />
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </div>
  );
}
