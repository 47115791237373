import { Layouts, Loading, DocModal } from "@components";
import {
  Form,
  Input,
  Button,
  Divider,
  Select,
  message,
  Descriptions,
  PageHeader,
  Tag,
} from "antd";
import { Link, useParams } from "react-router-dom";
import { SaveOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import * as API from "@api/admin";
import DocSelector from "../reports/docselector";

export default function NewRerort() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [doc, setDoc] = useState(null);
  const [relatedDocId, setRelatedDocId] = useState(null);
  const [docModalVisible, setDocModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Docs.getDoc({ id });

      if (_res.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      if (_res.data.parent_id) {
        setRelatedDocId(_res.data.parent_id);
      }

      setDoc(_res.data);
      setIsLoading(false);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    setIsLoading(true);

    const _res = await API.Docs.updateDoc({
      id: doc.id,
      name: data.name,
      status: data.status,
      parent_id: relatedDocId,
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Не удалось обновить документ", 2);
    }

    message.success("Документ обновлен", 1, () => document.location.reload());
  };

  if (isLoading || !doc) {
    return (
      <Layouts.AdminLayout>
        <Loading />
      </Layouts.AdminLayout>
    );
  }

  return (
    <Layouts.AdminLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Документ"
        subTitle="Посмотрите или отредактируйте существующий документ артиста"
      />
      <Descriptions title={doc.name} layout="vertical">
        <Descriptions.Item label="Название">
          <h3 className="m-0">{doc.name}</h3>
        </Descriptions.Item>
        <Descriptions.Item label="Файл документа">
          {doc.type === 1 && doc.url && (
            <a onClick={() => setModalVisible(true)}>Открыть</a>
          )}
          {doc.type === 2 && doc.url && (
            <a target="_blank" href={doc.url}>
              Открыть
            </a>
          )}
        </Descriptions.Item>
        {doc.parent && (
          <Descriptions.Item label="Подвязанный документ">
            <a href={`/docs/edit/${doc.parent.id}`}>Открыть</a>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Получатель">
          <Link to={`/users/edit/${doc.target_user_id}`}>
            {doc.target_user.first_name} {doc.target_user.last_name}
          </Link>
        </Descriptions.Item>
        {doc.target_user.artist && (
          <Descriptions.Item label="Получатель (артист)">
            <Link to={`/artists/view/${doc.target_user.artist.id}`}>
              {doc.target_user.artist.name}
            </Link>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Статус">
          {doc.status === "new" && (
            <Tag color="blue">Новый (не просмотрен)</Tag>
          )}
          {doc.status === "opened" && <Tag>Просмотрен</Tag>}
          {doc.status === "rejected" && <Tag color="red">Отклонен</Tag>}
          {doc.status === "accepted" && <Tag color="success">Принят</Tag>}
        </Descriptions.Item>
      </Descriptions>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFormSubmit}
        onFinishFailed={() => {}}
        autoComplete="off"
        style={{ maxWidth: 600, margin: "50px auto" }}
        labelAlign="left"
        initialValues={doc}
      >
        <Form.Item
          name="id"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
          hidden
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Название"
          name="name"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input placeholder="Напр. Акт выполненных работ" />
        </Form.Item>

        <Form.Item
          extra="Если это дочерний или связанный с другим документ, пожалуйста, выберите"
          label="Подвязанный документ"
          name="parent_id"
        >
          {relatedDocId ? (
            <Button danger type="primary" onClick={() => setRelatedDocId(null)}>
              Отвязать документ
            </Button>
          ) : (
            <Button onClick={() => setDocModalVisible(true)}>
              Выбрать документ
            </Button>
          )}
        </Form.Item>

        <Divider />

        <Form.Item
          extra="Выставите статус документу"
          label="Статус"
          name="status"
          allowClear={false}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Select>
            <Select.Option value="new">Новый</Select.Option>
            <Select.Option value="opened">Просмотрен</Select.Option>
            <Select.Option value="accepted">Принят</Select.Option>
            <Select.Option value="rejected">Отклонен</Select.Option>
          </Select>
        </Form.Item>

        <Divider />

        <Form.Item className="mt-4">
          <Button
            loading={isLoading}
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
        </Form.Item>
      </Form>
      {doc && (
        <DocSelector
          onDocModalClose={() => setDocModalVisible(false)}
          onDocSelected={({ id }) =>
            setRelatedDocId(id, setDocModalVisible(false))
          }
          visible={docModalVisible}
          user_id={doc.target_user.id}
        />
      )}
      <DocModal
        isAdmin
        doc={doc}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </Layouts.AdminLayout>
  );
}
