import { Layouts } from "@components";
import { Button, Table, Badge, Tag, Tabs, Input } from "antd";
import { SearchOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PageHeader } from "antd";
import moment from "moment";
import * as R from "ramda";
import Incoming from "./incoming";
import Outgoing from "./outgoing";

const { TabPane } = Tabs;

export default function Reports() {
  return (
    <Layouts.AdminLayout className="tracks-page">
      <PageHeader
        className="site-page-header"
        title="Документы"
        subTitle="Просматривайте и управляйте документами на платформе"
      />
      <Tabs defaultActiveKey="1">
        <TabPane forceRender tab="Входящие" key="1">
          <Incoming />
        </TabPane>
        <TabPane forceRender tab="Исходящие" key="2">
          <Outgoing />
        </TabPane>
      </Tabs>
    </Layouts.AdminLayout>
  );
}
