import React, { useLayoutEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

export default function PDFViewerMini(params) {
  const [width, setWidth] = useState(params.width || 0);
  const [height, setHeight] = useState(params.height || 0);
  const pageRef = useRef(null);

  useLayoutEffect(() => {
    if (pageRef.current && !width && !height) {
      setWidth(pageRef.current.clientWidth);
      setHeight(pageRef.current.clientHeight);
    }
  }, [pageRef.current]);

  return (
    <div className="doc mini">
      <Document
        file={params.url}
        error={
          <img
            src="/img/preview-error.svg"
            alt="Ошибка"
            style={{
              display: "block",
              width: "100%",
              objectFit: "contain",
              objectPosition: "center",
              maxWidth: 200,
              margin: "auto",
            }}
          />
        }
      >
        <Page
          inputRef={pageRef}
          height={height}
          width={width}
          pageIndex={0}
          className="page mini"
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
    </div>
  );
}
