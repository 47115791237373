import axios from "axios";
import config from "@config";

const API = `${config.api}/auth`;

// Возвращает статус пользователя
export const getStatus = () =>
  axios
    .get(API, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Регистрирует пользователя
export const register = (data) =>
  axios
    .post(`${API}/register`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Производит вход пользователя
export const signin = (data) =>
  axios
    .post(`${API}/signin`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Производит выход пользователя
export const logout = (data) =>
  axios
    .post(`${API}/logout`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Запрашивает SMS
export const requestSMS = (data) =>
  axios
    .post(`${API}/phone/send-code`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
// Подтверждает SMS
export const confirmSMS = (data) =>
  axios
    .post(`${API}/phone/confirm`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
