import { Layout, Menu, Breadcrumb, Dropdown, Avatar } from "antd";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  IdcardOutlined,
  GlobalOutlined,
  FileTextOutlined,
  UserOutlined,
  TeamOutlined,
  LogoutOutlined,
  FileDoneOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { isEmpty } from "ramda";

const { Header, Content, Footer, Sider } = Layout;

const usermenu = (
  <Menu>
    <Menu.Item>
      <Link to="/profile">Редактировать профиль</Link>
    </Menu.Item>
    <Menu.Item danger>
      <Link to="/logout">Выйти из аккаунта</Link>
    </Menu.Item>
  </Menu>
);

export default function ArtistLayout(props) {
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const { breadcrumbs = [], disableInteraction = false, children } = props;
  const location = useLocation();

  const selectedKeys = (alias) => {
    if (location.pathname === "/") {
      return ["home"];
    }

    const loc = location.pathname.substr(1).split("/");
    return [loc[0]];
  };

  const onCollapse = (collapsed) => {
    setIsNavCollapsed(collapsed);
  };

  return (
    <Layout {...props} style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={isNavCollapsed} onCollapse={onCollapse}>
        <div className="logo-top">
          <img
            className="logo-full"
            alt="Логотип"
            src={
              isNavCollapsed
                ? "/img/logo-light-compact.svg"
                : "/img/logo-light.svg"
            }
          />
        </div>
        <Menu
          theme="dark"
          selectedKeys={selectedKeys()}
          defaultSelectedKeys={["home"]}
          mode="inline"
        >
          <Menu.Item
            disabled={disableInteraction}
            key="home"
            icon={<HomeOutlined />}
          >
            <Link to="/">Главная</Link>
          </Menu.Item>
          <Menu.Item
            disabled={disableInteraction}
            key="applications"
            icon={<FileDoneOutlined />}
          >
            <Link to="/applications">Заявки</Link>
          </Menu.Item>
          <Menu.Item
            disabled={disableInteraction}
            key="artists"
            icon={<IdcardOutlined />}
          >
            <Link to="/artists">Артисты</Link>
          </Menu.Item>
          <Menu.Item
            disabled={disableInteraction}
            key="users"
            icon={<IdcardOutlined />}
          >
            <Link to="/users">Пользователи</Link>
          </Menu.Item>
          <Menu.Item
            disabled={disableInteraction}
            key="tracks"
            icon={<AudioOutlined />}
          >
            <Link to="/tracks">Треки</Link>
          </Menu.Item>
          <Menu.Item
            disabled={disableInteraction}
            key="albums"
            icon={<AudioOutlined />}
          >
            <Link to="/albums">Альбомы</Link>
          </Menu.Item>
          <Menu.Item
            disabled={disableInteraction}
            key="reports"
            icon={<GlobalOutlined />}
          >
            <Link to="/reports">Отчеты</Link>
          </Menu.Item>
          <Menu.Item
            disabled={disableInteraction}
            key="docs"
            icon={<FileTextOutlined />}
          >
            <Link to="/docs">Документы</Link>
          </Menu.Item>
          <Menu.Divider style={{ backgroundColor: "rgba(255,255,255,.1)" }} />
          <Menu.Item
            disabled={disableInteraction}
            key="help"
            icon={<TeamOutlined />}
          >
            <Link to="/help">Помощь</Link>
          </Menu.Item>
          <Menu.Divider style={{ backgroundColor: "rgba(255,255,255,.1)" }} />
          <Menu.Item danger key="logout" icon={<LogoutOutlined />}>
            <Link to="/logout">Выйти</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background site-header"
          style={{ padding: 0 }}
        >
          <Dropdown overlay={usermenu}>
            <Avatar style={{ cursor: "pointer" }} icon={<UserOutlined />} />
          </Dropdown>
        </Header>
        <Content style={{ margin: "16px" }}>
          <Breadcrumb
            className={isEmpty(breadcrumbs) && "d-none"}
            style={{ margin: "16px 0" }}
          >
            <Breadcrumb.Item>
              <a href="/">Главная</a>
            </Breadcrumb.Item>
            {breadcrumbs.map((nav) => (
              <Breadcrumb.Item>
                <a href={nav.link}>{nav.title}</a>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <div
            className={`site-layout-background ${props.className}`}
            style={{ padding: 24, minHeight: 360 }}
          >
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Musan Digital © {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
}
