import axios from "axios";
import config from "@config";

const API = `${config.api}/applications`;

// Возвращает отправленные заявки артиста
export const getApplications = (params) =>
  axios
    .get(API, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Создает новую заявку артиста
export const submitApplication = (data) =>
  axios
    .post(`${API}/submit`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
