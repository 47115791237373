import { Layouts, Loading, Player } from "@components";
import {
  Form,
  Input,
  Button,
  Upload,
  Divider,
  Select,
  message,
  Tag,
  Menu,
  Dropdown,
  Modal,
} from "antd";
import { useParams } from "react-router-dom";
import { UploadOutlined, SaveOutlined, RightOutlined } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import { PageHeader } from "antd";
import config from "@config";
import * as API from "@api";
import * as R from "ramda";

export default function ArtistTrackEdit() {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [albums, setAlbums] = useState([]);
  const [track, setTrack] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    // Подгружаем данные трека
    async function fetchInitData() {
      const _res = {
        albums: await API.Albums.getAlbums(),
        track: await API.Tracks.getTrack({ id }),
      };

      if (_res.albums.error || _res.track.error) {
        return message.error("Невозможно загрузить альбомы", 2);
      }

      setIsLoading(false);
      setAlbums(_res.albums.data);
      setTrack(_res.track.data);

      formRef.current.setFieldsValue(_res.track.data);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    // Формируем данные формы для отправки
    setIsLoading(true);
    const formData = new FormData();
    formData.append("id", track.id);
    formData.append("name", data.name);
    formData.append("name_en", data.name_en);
    formData.append("meta", JSON.stringify(data.meta || []));

    // Альбом
    if (data.album_id) {
      formData.append("album_id", data.album_id);
    }

    // Вложение аудио
    if (R.pathOr(null, ["audio", "file"], data)) {
      formData.append("audio", data.audio.file);
    }

    // Вложение видео
    if (R.pathOr(null, ["image", "file"], data)) {
      formData.append("image", data.image.file);
    }

    const _res = await API.Tracks.updateTrack({ id: track.id, data: formData });

    setIsLoading(false);

    if (_res.error) {
      return message.error(
        "Не удалось обновить трек",
        1,
        document.location.reload()
      );
    }

    message.success("Трек обновлен", 1, () => document.location.reload());
  };

  const handleMenuClick = async (btnId) => {
    Modal.confirm({
      title: "Обновить статус трека",
      content: "Вы собираетесь обносить статус трека. Пожалуйста, подтвердите.",
      onOk: async () => {
        formRef.current.submit();
        const status = btnId.key;

        setIsLoading(true);
        const _res = await API.Tracks.updateStatus({
          id: track.id,
          status,
        });
        setIsLoading(false);

        if (_res.error) {
          return message.error("Не удалось обновить статус трека", 2);
        }

        message.success("Статус трека обновлен", 1, () =>
          document.location.reload()
        );
      },
      okText: "Подтвердить",
    });
  };

  // Статус трека
  const getStatus = (id) => {
    switch (id) {
      case "new":
        return <Tag>Загружен</Tag>;

      case "pending":
        return <Tag color="blue">Отправлен на проверку</Tag>;

      case "rejected":
        return <Tag color="red">Отклонен модератором</Tag>;

      case "awaiting_artist":
        return <Tag color="orange">Ожидает вашего действия</Tag>;

      case "published":
        return <Tag color="success">Трек опубликован</Tag>;

      case "removed":
        return <Tag color="red">Удален</Tag>;

      default:
        return <Tag>Загружен</Tag>;
    }
  };

  const form = (
    <Form
      name="track"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFormSubmit}
      onFinishFailed={() => {}}
      ref={formRef}
      style={{ maxWidth: 500, margin: "20px auto" }}
      labelAlign="left"
    >
      <Form.Item name="id" hidden>
        <Input htmlType="hidden" disabled />
      </Form.Item>

      <Form.Item label="Статус" name="status">
        {getStatus(R.pathOr(null, ["status"], track))}
      </Form.Item>

      {R.pathOr(null, ["upc"], track) && (
        <Form.Item label="UPC" name="upc">
          {track.upc}
        </Form.Item>
      )}

      {R.pathOr(null, ["isrc"], track) && (
        <Form.Item label="ISRC" name="isrc">
          {track.isrc}
        </Form.Item>
      )}

      <Form.Item
        label="Название"
        name="name"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Название на англ." name="name_en">
        <Input />
      </Form.Item>

      <Form.Item
        extra="Выберите принадлежность к альбому, если необходимо"
        label="Альбом"
        name="album_id"
      >
        <Select
          allowClear
          options={albums.map((album) => {
            return { label: album.name, value: album.id };
          })}
        />
      </Form.Item>

      <Divider />

      <Form.Item label="Текст песни" name={["meta", "lyrics"]}>
        <Input.TextArea rows={5} />
      </Form.Item>

      <Divider />

      <Form.Item
        name="image"
        label="Изображение"
        extra="Загрузите, чтобы заменить текущее изображение"
      >
        <Upload
          beforeUpload={() => false}
          accept="image/*"
          name="image"
          listType="picture"
        >
          <Button icon={<UploadOutlined />}>Выбрать изображение</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="audio"
        label="Файл трека"
        extra="Загрузите, чтобы заменить текущий аудиофайл"
      >
        <Upload beforeUpload={() => false} accept=".mp3,audio/*" name="audio">
          <Button icon={<UploadOutlined />}>Выбрать аудиофайл</Button>
        </Upload>
      </Form.Item>

      <Divider />

      <Form.Item className="mt-4" noStyle>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            loading={isLoading}
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
            disabled={
              track?.status === "pending" ||
              track?.status === "awaiting_artst" ||
              track?.status === "published" ||
              track?.status === "removed"
            }
          >
            Сохранить
          </Button>
          <Dropdown.Button
            onClick={() =>
              handleMenuClick({
                key: "pending",
              })
            }
            disabled={
              track?.status === "pending" || track?.status === "removed"
            }
            overlay={
              <Menu onClick={handleMenuClick}>
                <Menu.Item key="pending">Отправить на модерацию</Menu.Item>
                <Menu.Item key="removed">Снять с ротации</Menu.Item>
              </Menu>
            }
          >
            На модерацию <RightOutlined />
          </Dropdown.Button>
        </div>
      </Form.Item>
    </Form>
  );

  return (
    <Layouts.ArtistLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Новый трек"
        subTitle="Загрузите новый трек на платформу"
      />
      <div className="row">
        <div className="col-12 col-lg-6 offset-lg-3">
          <Player
            url={track && `${config.paths.s3}/${track.url}`}
            title={track && track.name}
            image={track && `${config.paths.s3}/${track.image}`}
          />
        </div>
      </div>
      {isLoading ? <Loading /> : form}
    </Layouts.ArtistLayout>
  );
}
