import axios from "axios";
import config from "@config";

const API = `${config.api}/albums`;

// Возвращает альбомы артиста
export const getAlbums = (params) =>
  axios
    .get(API, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает альбом артиста
export const getAlbum = (params) =>
  axios
    .get(`${API}/id/${params.id}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Создает новый альбом
export const createAlbum = (data) =>
  axios
    .post(`${API}/create`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Обновляет альбом
export const updateAlbum = (data) =>
  axios
    .post(`${API}/update/id/${data.id}`, data.data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Обновляет статус альбом
export const updateStatus = (data) =>
  axios
    .post(`${API}/update/id/${data.id}/status`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

