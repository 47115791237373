import { useState, useRef, useEffect } from "react";
import { Layouts, Loading } from "@components";
import {
  Alert,
  Form,
  Input,
  Button,
  Divider,
  Select,
  message,
  PageHeader,
} from "antd";
import { Link } from "react-router-dom";
import MaskedInput from "antd-mask-input";
import * as API from "@api/admin";
import * as R from "ramda";
import { useParams } from "react-router";

export default function EditArtist(params) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [companyType, setCompanyType] = useState("individual");
  const { id } = useParams();

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Users.getUser({ id });

      if (_res.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      setUser(_res.data);
      setCompanyType(R.pathOr("individual", ["data", "company", "type"], _res));
      setIsLoading(false);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    setIsLoading(true);

    const res = await API.Users.updateUser(data);
    setIsLoading(false);

    if (res.error) {
      return message.error(
        "Что-то пошло не так. Попробуйте снова или обратитесь в поддержку.",
        2
      );
    }

    return message.success("Данные обновлены", 1, () =>
      document.location.reload()
    );
  };

  const form = user && (
    <Form
      name="user"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFormSubmit}
      onFinishFailed={() => {}}
      autoComplete="off"
      initialValues={user}
      style={{ maxWidth: 500, margin: "50px auto" }}
      labelAlign="left"
      scrollToFirstError
    >
      <Alert
        className="mb-5"
        type="error"
        message={
          <p className="m-0">
            <strong>Внимание: </strong> при изменении данных пользователя,
            обновятся так же данные связанных договоров. Редактируйте данные
            только если в этом есть необходимость (напр. пользователь сам
            попросил изменить данные).
          </p>
        }
      />
      <Form.Item label="ID" name="id" hidden>
        <Input htmlType="hidden" />
      </Form.Item>
      <Form.Item
        label="Имя"
        name="first_name"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Фамилия"
        name="last_name"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="tel"
        label="Номер телефона"
        rules={[
          {
            required: true,
            message: "Пожалуйста, введите номер телефона",
          },
        ]}
      >
        <MaskedInput
          mask="+7 111 1111111"
          placeholder="Телефон (напр. +7 701 1234567)"
        />
      </Form.Item>

      <Form.Item name="tel_confirmed" label="Подтверждение номера телефона">
        <Select>
          <Select.Option value={true}>Подтержден</Select.Option>
          <Select.Option value={false}>Неподтвержден</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="email"
        label="Эл.почта"
        rules={[
          {
            required: true,
            message: "Это поле обязательно к заполнению",
          },
        ]}
      >
        <Input type="email" />
      </Form.Item>

      <Divider />

      <h3>Данные об организации</h3>

      <Form.Item
        extra="Выберите вид организации"
        label="Вид организации"
        name={["company", "type"]}
        initialValue={companyType}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Select allowClear={false} onChange={(val) => setCompanyType(val)}>
          <Select.Option value="individual">Физ. лицо</Select.Option>
          <Select.Option value="ip">ИП</Select.Option>
          <Select.Option value="too">ТОО</Select.Option>
        </Select>
      </Form.Item>

      <h4>Удостоверение личности</h4>

      <Form.Item
        extra="Введите номер уд. личности"
        label="Номер уд. личности"
        name={["company", "id", "number"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input type="number" prefix="№" />
      </Form.Item>

      <Form.Item
        extra="Введите ИИН"
        label="ИИН"
        name={["company", "id", "iin"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input placeholder="123456789012" minLength={12} maxLength={12} />
      </Form.Item>

      <Form.Item
        extra="Напишите кем выдано уд. личности (напрмер, МВД РК)"
        label="Кем выдан"
        name={["company", "id", "issuer"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input placeholder="Напр. МВД РК" />
      </Form.Item>

      <Form.Item
        extra="Напишите дату выдачи уд.личности (напр. 04.2020)"
        label="Когда выдан"
        name={["company", "id", "issue_date"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <MaskedInput mask="11.1111" placeholder="04.2020" />
      </Form.Item>

      {companyType !== "individual" && (
        <Form.Item
          extra={"Введите название ИП / ТОО полностью"}
          label="Название организации"
          name={["company", "name"]}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input placeholder="Напр. ИП Иванов Иван или ТОО GAKKU" />
        </Form.Item>
      )}

      {companyType === "too" && (
        <Form.Item
          extra={"Введите БИН компании"}
          label="БИН"
          name={["company", "bin"]}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input />
        </Form.Item>
      )}

      <Form.Item
        extra={"Введите адрес проживания (или офиса если ИП/ТОО)"}
        label="Адрес"
        name={["company", "address"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input.TextArea
          rows={3}
          placeholder="Напр. Ул. Пушкина, д.4, 010000, г. Нур-Султан"
        />
      </Form.Item>

      <Divider />

      <h3>Артист</h3>
      {user?.artist ? (
        <Link to={`/artists/view/${user.artist.id}`}>{user.artist.name}</Link>
      ) : (
        "Артист не зарегистрирован"
      )}

      <Divider />

      <Form.Item className="mt-4">
        <Button loading={isLoading} type="primary" htmlType="submit">
          Отправить
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Layouts.AdminLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Данные пользователя"
        subTitle="Редактируйте данные зарегистрированного пользователя"
      />
      {form}
    </Layouts.AdminLayout>
  );
}
