import { Layouts, Loading, ReportModal, DocModal } from "@components";
import { Card, message, Button, Tabs, Typography, List, Tag } from "antd";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GlobalOutlined } from "@ant-design/icons";
import moment from "moment";
import * as API from "@api";
import * as R from "ramda";
import "moment/locale/ru";

const { TabPane } = Tabs;

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [reports, setReports] = useState([]);
  const [report, setReport] = useState(null);
  const [doc, setDoc] = useState(null);

  // Modals
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [docModalVisible, setDocModalVisible] = useState(false);

  useEffect(() => {
    async function fetchInitData() {
      const _res = {
        user: await API.Users.getDetails(),
        documents: await API.Docs.getIncomingDocs({ limit: 5 }),
        reports: await API.Reports.getReports({ limit: 5 }),
      };

      if (_res.user.error || _res.documents.error || _res.reports.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      setUser(_res.user.data);
      setDocuments(_res.documents.data);
      setReports(_res.reports.data);
      setIsLoading(false);
    }

    fetchInitData();
  }, []);

  const onReportOpen = async ({ id }) => {
    const report = await API.Reports.getReport({ id });

    if (report.error) {
      return message.error("Невозможно открыть отчет", 2);
    }

    setReport(report.data, setReportModalVisible(true));
  };

  const onDocOpen = async ({ id }) => {
    const doc = await API.Docs.getDoc({ id });

    if (doc.error) {
      return message.error("Невозможно открыть отчет", 2);
    }

    setDoc(doc.data, setDocModalVisible(true));
  };

  const onReportModalClose = () => {
    setReportModalVisible(false);
  };

  const onDocModalClose = () => {
    setDocModalVisible(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Layouts.ArtistLayout className="dashboard">
      <div
        className="dashboard-hero"
        style={{
          background: "url('/img/hero-dashboard.jpg') center center no-repeat",
          backgroundSize: "cover",
        }}
      >
        <h1>Добро пожаловать, {user.first_name} 👋</h1>
        <p>
          Платформа дистрибуции позволяет обмениваться документами, подписывать
          отчеты, создавать треки и альбомы в пару кликов. Это быстрее, удобнее,
          а главное, теперь нет необходимости посещать наши офисы.
        </p>
        <Link to="/tracks/new">
          <Button>Загрузить трек</Button>
        </Link>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <Card
            title="Последние отчеты"
            extra={
              <Link to="/reports">
                <Button type="primary">Все отчеты</Button>
              </Link>
            }
          >
            <List
              itemLayout="vertical"
              dataSource={reports}
              renderItem={(item) => (
                <List.Item>
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <Typography.Title className="m-0 mb-1" level={5} strong>
                      {item.name}
                    </Typography.Title>
                    <Button
                      size="small"
                      onClick={() => onReportOpen({ id: item.id })}
                    >
                      Открыть
                    </Button>
                  </div>
                  <Typography.Text>
                    Период:{" "}
                    {moment(item.period_start).locale("ru").format("MMMM YYYY")}{" "}
                    — {moment(item.period_end).locale("ru").format("MMMM YYYY")}
                  </Typography.Text>
                  <br />
                  <Typography.Text>
                    Создан:{" "}
                    {moment(item.createdAt)
                      .locale("ru")
                      .format("DD MMMM YYYY HH:mm")}
                  </Typography.Text>
                  <div className="d-flex mt-2 mb-2 justify-content-start align-items-center flex-wrap">
                    {item.status === "new" && <Tag color="blue">Новый</Tag>}
                    {item.requires_artist_signature &&
                      (item.artist_signed ? (
                        <Tag color="green">Документ подписан</Tag>
                      ) : (
                        <Tag color="orange">Нужна подпись</Tag>
                      ))}
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </div>
        <div className="col-12 col-lg-6">
          <Card
            title="Последние документы"
            extra={
              <Link to="/docs">
                <Button type="primary">Все документы</Button>
              </Link>
            }
          >
            <List
              itemLayout="vertical"
              dataSource={documents}
              renderItem={(item) => (
                <List.Item>
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <Typography.Title className="m-0 mb-1" level={5} strong>
                      {item.name}
                    </Typography.Title>
                    <Button
                      size="small"
                      onClick={() => onDocOpen({ id: item.id })}
                    >
                      Открыть
                    </Button>
                  </div>
                  <Typography.Text>
                    Создан:{" "}
                    {moment(item.createdAt)
                      .locale("ru")
                      .format("DD MMMM YYYY HH:mm")}
                  </Typography.Text>
                  <div className="d-flex mt-2 mb-2 justify-content-start align-items-center flex-wrap">
                    {item.status === "new" ? (
                      <Tag color="blue">Новый</Tag>
                    ) : (
                      <Tag>Просмотрен</Tag>
                    )}
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </div>
      </div>
      <ReportModal
        report={report}
        visible={reportModalVisible}
        onClose={onReportModalClose}
      />
      <DocModal doc={doc} visible={docModalVisible} onClose={onDocModalClose} />
    </Layouts.ArtistLayout>
  );
}
