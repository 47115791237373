import axios from "axios";
import config from "@config";

const API = `${config.api}/admin/albums`;

// Возвращает все альбомы
export const getAlbums = (params) =>
  axios
    .get(`${API}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает альбом по ID
export const getAlbum = (params) =>
  axios
    .get(`${API}/id/${params.id}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Обновляет альбом
export const updateAlbum = (data) =>
  axios
    .post(`${API}/update/id/${data.id}`, data.data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
