import { Layouts } from "@components";
import { Form, Input, Button, Upload, Divider, Select, message } from "antd";
import { useHistory } from "react-router-dom";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { PageHeader } from "antd";
import * as API from "@api";

export default function ArtistTrackNew() {
  const [isLoading, setIsLoading] = useState(true);
  const [albums, setAlbums] = useState([]);
  const router = useHistory();

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Albums.getAlbums();

      setIsLoading(false);

      if (_res.error) {
        return message.error("Невозможно загрузить альбомы", 2);
      }

      setAlbums(_res.data);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("name_en", data.name_en);
    formData.append("meta", JSON.stringify(data.meta || []));
    formData.append("audio", data.audio.file);
    formData.append("image", data.image.file);

    // Альбом
    if (data.album_id) {
      formData.append("album_id", data.album_id);
    }

    const _res = await API.Tracks.createTrack(formData);

    setIsLoading(false);

    if (_res.error) {
      return message.error("Не удалось создать трек", 2);
    }

    message.success("Трек создан", 1, () => router.push("/tracks"));
  };

  return (
    <Layouts.ArtistLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Новый трек"
        subTitle="Загрузите новый трек на платформу"
      />
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFormSubmit}
        onFinishFailed={() => {}}
        autoComplete="off"
        style={{ maxWidth: 500, margin: "50px auto" }}
        labelAlign="left"
      >
        <Form.Item
          label="Название"
          name="name"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Название на англ." name="name_en">
          <Input />
        </Form.Item>

        <Form.Item
          extra="Выберите принадлежность к альбому, если необходимо"
          label="Альбом"
          name="album_id"
        >
          <Select
            allowClear
            options={albums.map((album) => {
              return { label: album.name, value: album.id };
            })}
          />
        </Form.Item>

        <Divider />

        <Form.Item label="Текст песни" name={["meta", "lyrics"]}>
          <Input.TextArea rows={5} />
        </Form.Item>

        <Divider />

        <Form.Item
          name="image"
          label="Изображение"
          extra="Выберите изображение (макс. 5 МБ)"
          rules={[
            {
              required: true,
              message: "Пожалуйста, загрузите креатив трека",
            },
          ]}
        >
          <Upload
            beforeUpload={() => false}
            accept="image/*"
            name="image"
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Выбрать изображение</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="audio"
          label="Файл трека"
          extra="Выберите превью-трек (макс. 15 МБ)"
          rules={[{ required: true, message: "Пожалуйста, загрузите трек" }]}
        >
          <Upload beforeUpload={() => false} accept=".mp3,audio/*" name="audio">
            <Button icon={<UploadOutlined />}>Выбрать аудиофайл</Button>
          </Upload>
        </Form.Item>

        <Divider />

        <Form.Item className="mt-4">
          <Button
            loading={isLoading}
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Layouts.ArtistLayout>
  );
}
