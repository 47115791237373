import { useState } from "react";
import { Modal, Button, Popconfirm, message, Typography, Divider } from "antd";
import { Link } from "react-router-dom";
import {
  EditOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { PDFViewerMini } from "@components";
import * as API from "@api";
import * as API_ADMIN from "@api/admin";
import config from "@config";

export default function ReportModal(params) {
  const [isLoading, setIsLoading] = useState(false);

  if (!params.report) {
    return null;
  }

  async function onDocSign() {
    setIsLoading(true);
    let _res = null;

    if (params.isAdmin) {
      _res = await API_ADMIN.Reports.updateReport({
        id: params.report.id,
        moderator_signed: true,
      });
    } else {
      _res = await API.Reports.signReport(params.report);
    }

    setIsLoading(false);

    if (_res.error) {
      return message.error("Ошибка при подписании документа", 3);
    }

    return message.success("Документ подписан", 2, () =>
      document.location.reload()
    );
  }

  function getReportURL() {
    // If report type is External Report
    if (params.report.type === 3) {
      return params.report.url;
    }

    // If report is hosted in S3
    return `${config.paths.s3}/${params.report.url}`;
  }

  return (
    <Modal
      title={params.report.name}
      visible={params.visible}
      onCancel={params.onClose}
      closable={!isLoading}
      maskClosable={!isLoading}
      footer={[
        <Button loading={isLoading} key="back" onClick={params.onClose}>
          Закрыть
        </Button>,
      ]}
      className="doc-modal"
    >
      <div className="row">
        <div className="col-12 col-lg-6">
          <PDFViewerMini width={240} height={340} url={getReportURL()} />
        </div>
        <div className="col-12 col-lg-6 d-flex justify-content-start flex-column">
          <div class="item">
            <span className="item-title">Описание</span>
            <h3>{params.report?.description || "Без описания"}</h3>
          </div>
          <div class="item">
            <Link to={`/reports/view/${params.report.id}`} target="_blank">
              <Button
                style={{ width: "100%" }}
                className="btn"
                icon={<FileTextOutlined />}
                disabled={!params.report.url}
              >
                Открыть
              </Button>
            </Link>
            <Divider />
            {params.report.requires_artist_signature &&
              !params.report.artist_signed && [
                <Popconfirm
                  title="Подписать электронной подписью?"
                  onConfirm={onDocSign}
                  okText="Подписать"
                  cancelText="Отмена"
                >
                  <Button
                    icon={<EditOutlined />}
                    key="sign"
                    type="primary"
                    loading={isLoading}
                  >
                    Подписать
                  </Button>
                </Popconfirm>,
                <Typography.Text type="secondary" className="mb-2 mt-2">
                  <EditOutlined style={{ marginRight: 5 }} />
                  Этот документ можно подписать онлайн за 1 мин.
                </Typography.Text>,
              ]}
            {params.report.artist_signed && (
              <Typography.Text type="success" className="mb-2 mt-2">
                <CheckCircleOutlined style={{ marginRight: 5 }} />
                Вы подписали этот документ электронной подписью
              </Typography.Text>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
