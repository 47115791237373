import { Layouts } from "@components";
import { Button, Table, message, Tag } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PageHeader } from "antd";
import moment from "moment";
import * as API from "@api";
import config from "@config";

export default function ArtistAlbums() {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
  });

  useEffect(() => {
    async function fetchInitData() {
      const _res = {
        artist: await API.Artists.getArtist(),
        albums: await API.Albums.getAlbums({
          limit: pagination.pageSize,
          offset:
            pagination.current * pagination.pageSize - pagination.pageSize,
        }),
      };

      setIsLoading(false);

      if (_res.artist.error || _res.albums.error) {
        return message.error("Невозможно загрузить альбомы", 2);
      }

      setTableData(
        {
          artist: _res.artist.data,
          albums: _res.albums.data,
        },
        setPagination({
          ...pagination,
          total: _res.albums.pagination.total,
        })
      );
    }

    fetchInitData();
  }, []);

  const getAlbums = async (pagination) => {
    setIsLoading(true);

    const _res = await API.Albums.getAlbums({
      limit: pagination.pageSize,
      offset: pagination.current * pagination.pageSize - pagination.pageSize,
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Невозможно загрузить треки", 2);
    }

    setTableData(
      {
        ...tableData,
        albums: _res.data,
      },
      setPagination(pagination)
    );
  };

  const getStatus = (id) => {
    switch (id) {
      case "new":
        return <Tag>Загружен</Tag>;

      case "pending":
        return <Tag color="orange">Отправлен на проверку</Tag>;

      case "rejected":
        return <Tag color="red">Отклонен модератором</Tag>;

      case "published":
        return <Tag color="success">Альбом опубликован</Tag>;

      case "awaiting_artist":
        return <Tag color="orange">Ожидает вашего действия</Tag>;

      case "removed":
        return <Tag color="red">Удален</Tag>;

      default:
        return <Tag>Загружен</Tag>;
    }
  };

  const columns = [
    {
      dataIndex: "image",
      key: "image",
      render: (data) => (
        <img
          src={data ? `${config.paths.s3}/${data}` : "/img/icon-track.svg"}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/img/icon-track.svg";
          }}
          className="track-img"
          alt="Изображение трека"
        />
      ),
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (data) => <h4 className="m-0">{data}</h4>,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (data) => getStatus(data),
    },
    {
      title: "Посл. изменение",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (data) => (
        <p className="m-0">{moment(data).format("HH:mm DD.MM.YYYY")}</p>
      ),
    },
    {
      dataIndex: "actions",
      key: "actions",
      render: (data, all) => (
        <Link to={`/albums/edit/${all.id}`}>
          <Button>Открыть</Button>
        </Link>
      ),
    },
  ];

  return (
    <Layouts.ArtistLayout className="tracks-page">
      <PageHeader
        className="site-page-header"
        title="Мои альбомы"
        subTitle="Управляйте альбомами на платформе"
        extra={[
          <Link to="/albums/new">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              Новый альбом
            </Button>
          </Link>,
        ]}
      />
      <Table
        onChange={getAlbums}
        className="tracks-table"
        pagination={pagination}
        dataSource={tableData?.albums || []}
        columns={columns}
        loading={isLoading}
      />
    </Layouts.ArtistLayout>
  );
}
