import { useEffect } from "react";
import { Redirect } from "react-router";
import * as API from "@api";

export default function Logout(params) {
  useEffect(() => {
    async function logout() {
      await API.Auth.logout();
      params.onSignOut();
    }

    logout();
  });

  return <Redirect to="/signin" />;
}
