import { Layouts, Loading, ReportModal } from "@components";
import {
  Form,
  Input,
  Button,
  Divider,
  Select,
  message,
  Descriptions,
  PageHeader,
  DatePicker,
  Tag,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import * as API from "@api/admin";
import * as R from "ramda";
import config from "@config";
import DocSelector from "./docselector";
import moment from "moment";

export default function EditReport() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState(null);
  const [reportType, setReportType] = useState(2);
  const [relatedDocId, setRelatedDocId] = useState(null);
  const [docModalVisible, setDocModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const router = useHistory();

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Reports.getReport({ id });

      if (_res.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      if (_res.data.related_document_id) {
        setRelatedDocId(_res.data.related_document_id);
      }

      setReport(_res.data);
      setIsLoading(false);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    setIsLoading(true);

    const _res = await API.Reports.updateReport({
      id: report.id,
      ...data,
      related_document_id: relatedDocId,
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Не удалось обновить отчет", 2);
    }

    message.success("Отчет обновлен", 1, () => document.location.reload());
  };

  if (isLoading || !report) {
    return (
      <Layouts.AdminLayout>
        <Loading />
      </Layouts.AdminLayout>
    );
  }

  return (
    <Layouts.AdminLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Данные отчета"
        subTitle="Посмотрите или отредактируйте существующий отчет для артиста"
      />
      <Descriptions title={report.name} layout="vertical">
        <Descriptions.Item label="Файл отчета">
          {report.type === 2 && report.url && (
            <a onClick={() => setModalVisible(true)}>Открыть</a>
          )}
          {report.type === 3 && report.url && (
            <a target="_blank" href={report.url}>
              Открыть
            </a>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Отчетый период (начало)">
          {moment(report.period_start).format("MMMM YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="Отчетый период (конец)">
          {moment(report.period_end).format("MMMM YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="Статус">
          {report.status === "new" && <Tag color="blue">Новый</Tag>}
          {report.status === "opened" && <Tag>Открыт артистом</Tag>}
          {report.status === "rejected" && <Tag color="red">Отклонен</Tag>}
        </Descriptions.Item>
        <Descriptions.Item label="Подпись артиста">
          {report.requires_artist_signature && report.artist_signed && (
            <Tag color="green">Подписан артистом</Tag>
          )}
          {report.requires_artist_signature && !report.artist_signed && (
            <Tag color="orange">Ожидает подписи артиста</Tag>
          )}
          {!report.requires_artist_signature && <Tag>Не требуется</Tag>}
        </Descriptions.Item>
        <Descriptions.Item label="Дата создания отчета">
          {moment(report.createdAt).format("HH:mm DD.MM.YYYY")}
        </Descriptions.Item>
      </Descriptions>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFormSubmit}
        onFinishFailed={() => {}}
        autoComplete="off"
        style={{ maxWidth: 600, margin: "50px auto" }}
        labelAlign="left"
        initialValues={report}
      >
        <Form.Item
          name={["artist", "user_id"]}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
          hidden
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Артист"
          name={["artist", "name"]}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
          disabled
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Название"
          name="name"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input placeholder="Напр. Отчет за период 01.2020 - 04.2020" />
        </Form.Item>

        <Form.Item label="Краткое описание" name="description">
          <Input.TextArea rows={3} placeholder="Небольшое описание отчета" />
        </Form.Item>

        <Form.Item
          extra="Если отчет имеет подвязку к документу (напр. инвойс), прикрепите документ здесь"
          label="Подвязанный документ"
          name="related_document_id"
        >
          {relatedDocId ? (
            <Button danger type="primary" onClick={() => setRelatedDocId(null)}>
              Отвязать документ
            </Button>
          ) : (
            <Button onClick={() => setDocModalVisible(true)}>
              Выбрать документ
            </Button>
          )}
        </Form.Item>

        <Divider />

        <Form.Item
          label="Подпись артиста"
          name="requires_artist_signature"
          allowClear={false}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
          initialValue={true}
        >
          <Select>
            <Select.Option value={true}>
              Подпись артиста обязательна
            </Select.Option>
            <Select.Option value={false}>
              Подпись артиста не требуется
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Подпись модератора"
          name="requires_moderator_signature"
          allowClear={false}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
          initialValue={true}
        >
          <Select>
            <Select.Option value={true}>
              Подпись модератора обязательна
            </Select.Option>
            <Select.Option value={false}>
              Подпись модератора не требуется
            </Select.Option>
          </Select>
        </Form.Item>

        <Divider />

        <Form.Item className="mt-4">
          <Button
            loading={isLoading}
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
        </Form.Item>
      </Form>
      {report && (
        <DocSelector
          onDocModalClose={() => setDocModalVisible(false)}
          onDocSelected={({ id }) =>
            setRelatedDocId(id, setDocModalVisible(false))
          }
          visible={docModalVisible}
          user_id={report.artist.user_id}
        />
      )}
      <ReportModal
        isAdmin
        report={report}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </Layouts.AdminLayout>
  );
}
