import React, { Fragment } from "react";
import * as Pages from "@pages";
import { ProtectedRoute } from "@components";

export default function ArtistRoutes({ signedIn = false }) {
  return (
    <Fragment>
      <ProtectedRoute
        exact
        path="/profile"
        signedIn={signedIn}
        component={Pages.Profile}
      />
      <ProtectedRoute
        exact
        path="/contracts/view/:id"
        signedIn={signedIn}
        component={Pages.ViewContract}
      />
      <ProtectedRoute
        exact
        path="/tracks"
        signedIn={signedIn}
        component={Pages.ArtistTracks}
      />
      <ProtectedRoute
        exact
        path="/tracks/new"
        signedIn={signedIn}
        component={Pages.ArtistTrackNew}
      />
      <ProtectedRoute
        exact
        path="/tracks/edit/:id"
        signedIn={signedIn}
        component={Pages.ArtistTrackEdit}
      />
      <ProtectedRoute
        exact
        path="/albums"
        signedIn={signedIn}
        component={Pages.ArtistAlbums}
      />
      <ProtectedRoute
        exact
        path="/albums/new"
        signedIn={signedIn}
        component={Pages.ArtistAlbumNew}
      />
      <ProtectedRoute
        exact
        path="/albums/edit/:id"
        signedIn={signedIn}
        component={Pages.ArtistAlbumEdit}
      />
      <ProtectedRoute
        exact
        path="/reports"
        signedIn={signedIn}
        component={Pages.ArtistReports}
      />
      <ProtectedRoute
        exact
        path="/reports/view/:id"
        signedIn={signedIn}
        component={Pages.ArtistReport}
      />
      <ProtectedRoute
        exact
        path="/docs"
        signedIn={signedIn}
        component={Pages.ArtistDocs}
      />
      <ProtectedRoute
        exact
        path="/docs/new"
        signedIn={signedIn}
        component={Pages.ArtistDocNew}
      />
      <ProtectedRoute
        exact
        path="/"
        signedIn={signedIn}
        component={Pages.Dashboard}
      />
    </Fragment>
  );
}
