import { Modal, Button } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { PDFViewerMini } from "@components";
import moment from "moment";
import config from "@config";

export default function DocModal(params) {
  if (!params.doc) {
    return null;
  }

  function getDocURL() {
    if (params.doc.type === 2) {
      return params.report.content;
    }

    return `${config.paths.s3}/${params.doc.content}`;
  }

  function openDoc() {
    return window.open(getDocURL(), "_blank");
  }

  return (
    <Modal
      title={params.doc.name}
      visible={params.visible}
      onCancel={params.onClose}
      closable
      maskClosable
      footer={[
        <Button key="back" onClick={params.onClose}>
          Закрыть
        </Button>,
      ]}
      className="doc-modal"
    >
      <div className="row">
        <div className="col-12 col-lg-6">
          {params.doc.mimetype === "application/pdf" && (
            <PDFViewerMini width={240} height={340} url={getDocURL()} />
          )}
        </div>
        <div className="col-12 col-lg-6 d-flex justify-content-start flex-column">
          <div class="item">
            <span className="item-title">Документ</span>
            <h3>{params.doc.name}</h3>
          </div>
          <div class="item">
            <span className="item-title">Дата создания</span>
            <h3>{moment(params.doc.createdAt).format("HH:mm DD.MM.YYYY")}</h3>
          </div>
          <div class="item">
            <Button
              type="primary"
              style={{ width: "100%" }}
              className="btn"
              icon={<FileTextOutlined />}
              onClick={openDoc}
            >
              Открыть
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
