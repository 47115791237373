import axios from "axios";
import config from "@config";

const API = `${config.api}/users`;
const ADMIN_API = `${config.api}/admin/users`;

// Возвращает текущего пользователя
export const getDetails = () =>
  axios
    .get(`${API}/details`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает пользователей
export const getUsers = (params) =>
  axios
    .get(ADMIN_API, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает пользователя по ID
export const getUser = (params) =>
  axios
    .get(`${ADMIN_API}/id/${params.id}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Обновляет данные пользователя
export const updateUser = (data) =>
  axios
    .post(`${ADMIN_API}/update/id/${data.id}`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Обновляет данные пользователя
export const updateSelf = (data) =>
  axios
    .post(`${ADMIN_API}/update/self`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
