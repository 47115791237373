import { Layouts, Loading, Player } from "@components";
import {
  Form,
  Input,
  Button,
  Divider,
  message,
  Tag,
  Alert,
  Tooltip,
} from "antd";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { PageHeader } from "antd";
import { Link } from "react-router-dom";
import config from "@config";
import * as API from "@api/admin";
import * as R from "ramda";

export default function ViewApplication() {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [application, setApplication] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    async function fetchInitData() {
      const _application = await API.Applications.getApplication({ id });

      if (_application.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      setIsLoading(false);
      setApplication(_application.data);
      formRef.current.setFieldsValue(_application.data);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    const { status } = formRef.current.getFieldsValue(["status"]);

    // Формируем данные формы для отправки
    setIsLoading(true);

    const _res = await API.Applications.updateApplication({ ...data, status });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Не удалось завершить действие", 2);
    }

    message.success("Заявка успешно обновлена", 1, () =>
      document.location.reload()
    );
  };

  const getStatus = () => {
    switch (application?.status) {
      case "new":
        return (
          <Alert className="mb-4" message="Новая заявка" type="info" showIcon />
        );

      case "pending":
        return (
          <Alert
            className="mb-4"
            message="Ожидает действия"
            type="info"
            showIcon
          />
        );

      case "published":
        return (
          <Alert
            className="mb-4"
            message="Одобренная заявка"
            type="success"
            showIcon
          />
        );

      case "rejected":
        return (
          <Alert
            className="mb-4"
            message="Отклоненная заявка"
            type="error"
            showIcon
          />
        );

      default:
        break;
    }
  };

  const form = (
    <Form
      name="application"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFormSubmit}
      onFinishFailed={() => {}}
      ref={formRef}
      style={{ maxWidth: 500, margin: "20px auto" }}
      labelAlign="left"
      initialValues={application}
    >
      <Form.Item name="id" hidden>
        <Input htmlType="hidden" disabled />
      </Form.Item>

      {getStatus()}

      <h3>Информация об артисте/исполнителе</h3>
      <p className="mb-5">
        Данные предоставлены артистом через онлайн-заявку. Как только вы
        одобрите заявку, артист получит возможность пользоваться
        онлайн-платформой. Убедитесь, что данные артиста и заявителя
        соответствуют действительности.
      </p>

      <Form.Item
        label="Название"
        name="name"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input placeholder="Название артиста или группы" />
      </Form.Item>

      <Form.Item
        label="Название на англ."
        name="name_en"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input placeholder="Название на английском" />
      </Form.Item>

      <Form.Item
        label="Описание"
        name="description"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input.TextArea placeholder="Расскажите немного об артисте / группе" />
      </Form.Item>

      <Form.Item label="Обложка" name="image">
        <Input hidden />
        {application?.image ? (
          <img
            src={`${config.paths.s3}/${application?.image}`}
            style={{
              display: "block",
              objectFit: "contain",
              objectPosition: "center",
              width: "100%",
            }}
            alt="Обложка"
          />
        ) : (
          <Tag>Без обложки</Tag>
        )}
      </Form.Item>

      <Divider />

      <h3>Информация о заявителе</h3>
      <p className="mb-5">
        Заявитель — зарегистрированный пользователь платформы и ответственное
        лицо, которое будет представлять сторону артиста. Это может быть сам
        артист, либо его представитель. Данные ниже предоставлены заявителем и
        будут использоваться для формирования онлайн договоров. Если вы
        сомневаетесь в достоверности некоторых данных, вы можете запросить
        информацию или документы у заявителя и только потом одобрить заявку.
      </p>

      <Form.Item
        hidden
        label="ID"
        name={["user", "id"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input hidden />
      </Form.Item>

      <Form.Item
        label="Имя"
        name={["user", "first_name"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Фамилия"
        name={["user", "last_name"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Телефон"
        name={["user", "tel"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Эл.почта"
        name={["user", "email"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Divider />

      <h3 className="mt-3 mb-3">Удостоверение личности</h3>
      <Form.Item
        label="Номер уд. личности"
        name={["metadata", "company", "id", "number"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="ИИН заявителя"
        name={["metadata", "company", "id", "iin"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Выдано"
        name={["metadata", "company", "id", "issuer"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Когда выдан"
        name={["metadata", "company", "id", "issue_date"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Divider />

      <h3 className="mt-3 mb-3">Информация об организации</h3>

      <Form.Item
        label="Вид организации"
        name={["metadata", "company", "type"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        {application?.metadata?.company?.type === "individual" && (
          <Tag>Физ.лицо</Tag>
        )}
        {application?.metadata?.company?.type === "ip" && <Tag>ИП</Tag>}
        {application?.metadata?.company?.type === "too" && <Tag>ТОО</Tag>}
      </Form.Item>

      {application?.metadata?.company?.type !== "individual" && (
        <Form.Item
          label="Название ИП/ТОО"
          name={["metadata", "company", "name"]}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input />
        </Form.Item>
      )}

      {application?.metadata?.company?.type !== "individual" && (
        <Form.Item
          label="БИН/ИИН"
          name={["metadata", "company", "bin"]}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input />
        </Form.Item>
      )}

      <Form.Item
        label="Адрес"
        name={["metadata", "company", "address"]}
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>

      <Divider />

      <h3>Договор</h3>
      <p className="mb-3">
        Заявитель согласился с условиями Договора, дал согласие на обработку
        данных и использование онлайн-подписи. Договоры будут доступны к
        скачиванию после одобрения.
      </p>

      <ul>
        {application?.contracts.map((contract) => (
          <li key={contract.id}>
            {contract.template.name}{" "}
            <Link target="_blank" to={`/contracts/view/${contract.id}`}>
              <a alt={contract.template.name}>Открыть</a>
            </Link>
          </li>
        ))}
      </ul>

      <Divider />

      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <Form.Item className="mt-4">
          <Tooltip title="Одобрить заявку и создать профиль артиста">
            <Button
              onClick={() =>
                formRef.current.setFieldsValue({ status: "published" })
              }
              disabled={application?.status === "published"}
              loading={isLoading}
              type="primary"
              htmlType="submit"
            >
              Одобрить заявку
            </Button>
          </Tooltip>
        </Form.Item>
        <Form.Item className="mt-4">
          <Button
            disabled={application?.status === "published"}
            onClick={() =>
              formRef.current.setFieldsValue({ status: "rejected" })
            }
            htmlType="submit"
            loading={isLoading}
            type="link"
            danger
          >
            Отклонить заявку
          </Button>
        </Form.Item>
      </div>
    </Form>
  );

  return (
    <Layouts.AdminLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Информация о заявке"
        subTitle="Ознакомьтесь с заявкой от исполнителя. Вы можете одобрить или отклонить заявку."
      />
      <div className="row">
        <div className="col-12 col-lg-6 offset-lg-3">
          {R.pathOr(null, ["track"], application) && (
            <Player
              url={`${config.paths.s3}/${application.track}`}
              title={"Превью-трек артиста"}
            />
          )}
        </div>
      </div>
      {isLoading ? <Loading /> : form}
    </Layouts.AdminLayout>
  );
}
