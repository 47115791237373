import axios from "axios";
import config from "@config";

const API = `${config.api}/admin/tracks`;

// Возвращает треки
export const getTracks = (params) =>
  axios
    .get(API, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает треки
export const getTrack = (params) =>
  axios
    .get(`${API}/id/${params.id}`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Создает новый трек
export const createTrack = (data) =>
  axios
    .post(`${API}/create`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Обновляет трек
export const updateTrack = (data) =>
  axios
    .post(`${API}/update/id/${data.id}`, data.data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
