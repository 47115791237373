import { Layouts } from "@components";
import { Form, Input, Button, Upload, Divider, Select, message } from "antd";
import { useHistory } from "react-router-dom";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { PageHeader } from "antd";
import * as API from "@api";

export default function ArtistAlbumNew() {
  const [isLoading, setIsLoading] = useState(true);
  const [tracks, setTracks] = useState([]);
  const router = useHistory();

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Tracks.getTracks();

      setIsLoading(false);

      if (_res.error) {
        return message.error("Невозможно загрузить альбомы", 2);
      }

      setTracks(_res.data);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("name_en", data.name_en);
    formData.append("image", data.image.file);

    // Альбом
    if (data.tracks) {
      formData.append("tracks", data.tracks);
    }

    const _res = await API.Albums.createAlbum(formData);

    setIsLoading(false);

    if (_res.error) {
      return message.error("Не удалось создать альбом", 2);
    }

    message.success("Альбом создан", 1, () => router.push("/albums"));
  };

  const albumtypes = [
    { name: "Студийный альбом", id: 1 },
    { name: "EP (Extended Play)", id: 2 },
    { name: "Сборник (Compilation Album)", id: 3 },
    { name: "Концепт (Concept Album)", id: 4 },
    { name: "Live", id: 5 },
    { name: "Двойной (Double Album)", id: 6 },
    { name: "Хиты (Greatest Hits Album)", id: 7 },
    { name: "Ремиксы (Remix Album)", id: 8 },
    { name: "Дебютный альбом", id: 9 },
    { name: "Соло", id: 10 },
    { name: "Другое", id: 11 },
  ];

  return (
    <Layouts.ArtistLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Новый альбом"
        subTitle="Загрузите новый альбом на платформу"
      />
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFormSubmit}
        onFinishFailed={() => {}}
        autoComplete="off"
        style={{ maxWidth: 500, margin: "50px auto" }}
        labelAlign="left"
      >
        <Form.Item
          label="Название"
          name="name"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Название на англ."
          name="name_en"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          extra="Выберите тип альбома"
          label="Тип альбома"
          name="type"
          allowClear={false}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Select
            options={albumtypes.map((type) => {
              return { label: type.name, value: type.id };
            })}
          />
        </Form.Item>

        <Form.Item
          extra="Выберите треки альбома (если вы их уже загрузили)"
          label="Треки"
          name="tracks"
        >
          <Select
            allowClear
            mode="multiple"
            options={tracks.map((track) => {
              return { label: track.name, value: track.id };
            })}
          />
        </Form.Item>

        <Divider />

        <Form.Item
          name="image"
          label="Изображение"
          extra="Выберите изображение (макс. 5 МБ)"
          rules={[
            {
              required: true,
              message: "Пожалуйста, загрузите креатив альбома",
            },
          ]}
        >
          <Upload
            beforeUpload={() => false}
            accept="image/*"
            name="image"
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Выбрать изображение</Button>
          </Upload>
        </Form.Item>

        <Divider />

        <Form.Item className="mt-4">
          <Button
            loading={isLoading}
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
          >
            Отправить
          </Button>
        </Form.Item>
      </Form>
    </Layouts.ArtistLayout>
  );
}
