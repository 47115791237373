import axios from "axios";
import config from "@config";

const API = `${config.api}/admin/applications`;

// Возвращает все заявки на регистрацию для админа
export const getApplications = (params) =>
  axios
    .get(API, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает заявку
export const getApplication = ({ id }) =>
  axios
    .get(`${API}/id/${id}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает заявку
export const updateApplication = (data) =>
  axios
    .post(`${API}/update`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
