import { Layouts, DocModal } from "@components";
import { Button, Table, message, Tag, Tabs, Badge, Modal, Divider } from "antd";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import * as API from "@api/admin";
import * as R from "ramda";
import "moment/locale/ru";

const { TabPane } = Tabs;

export default function DocSelector(props) {
  const visible = props.visible || false;
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState("1");
  const [modalVisible, setModalVisible] = useState(false);
  const [doc, setDoc] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState({
    incoming: {
      current: 1,
      pageSize: 10,
      total: 1,
      position: ["bottomright"],
      showSizeChanger: true,
    },
    outgoing: {
      current: 1,
      pageSize: 10,
      total: 1,
      position: ["bottomright"],
      showSizeChanger: true,
    },
  });

  useEffect(() => {
    async function fetchInitData() {
      const _res = {
        incoming: await API.Docs.getUserIncomingDocs({
          id: props.user_id,
          limit: pagination.incoming.pageSize,
          offset:
            pagination.incoming.current * pagination.incoming.pageSize -
            pagination.incoming.pageSize,
        }),
        outgoing: await API.Docs.getUserOutgoingDocs({
          id: props.user_id,
          limit: pagination.outgoing.pageSize,
          offset:
            pagination.outgoing.current * pagination.outgoing.pageSize -
            pagination.outgoing.pageSize,
        }),
      };

      setIsLoading(false);

      if (_res.incoming.error || _res.outgoing.error) {
        return message.error("Невозможно загрузить документы", 2);
      }

      setTableData(
        {
          incoming: _res.incoming.data,
          outgoing: _res.outgoing.data,
        },
        setPagination({
          incoming: {
            ...pagination.incoming,
            total: _res.incoming.pagination.total,
          },
          outgoing: {
            ...pagination.outgoing,
            total: _res.outgoing.pagination.total,
          },
        })
      );
    }

    fetchInitData();
  }, []);

  const getIncomingDocs = async (p) => {
    setIsLoading(true);

    const _res = await API.Docs.getUserIncomingDocs({
      id: props.user_id,
      limit: p.pageSize,
      offset: p.current * p.pageSize - p.pageSize,
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Невозможно загрузить данные", 2);
    }

    setTableData(
      {
        ...tableData,
        incoming: _res.data,
      },
      setPagination({
        ...pagination,
        incoming: p,
      })
    );
  };

  const getOutgoingDocs = async (p) => {
    setIsLoading(true);

    const _res = await API.Docs.getUserOutgoingDocs({
      id: props.user_id,
      limit: p.pageSize,
      offset: p.current * p.pageSize - p.pageSize,
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Невозможно загрузить данные", 2);
    }

    setTableData(
      {
        ...tableData,
        outgoing: _res.data,
      },
      setPagination({
        ...pagination,
        outgoing: p,
      })
    );
  };

  const getStatus = (id) => {
    if (parseInt(currentTab) === 2) {
      return <Tag style={{ margin: 0 }}>Создан</Tag>;
    }

    switch (id) {
      case "new":
        return (
          <Tag style={{ margin: 0 }} color="blue">
            Новый
          </Tag>
        );

      case "opened":
        return <Tag style={{ margin: 0 }}>Просмотрен</Tag>;

      case "rejected":
        return (
          <Tag color="red" style={{ margin: 0 }}>
            Отклонен
          </Tag>
        );

      case "accepted":
        return (
          <Tag color="green" style={{ margin: 0 }}>
            Принят
          </Tag>
        );

      default:
        return <Tag style={{ margin: 0 }}>Загружен</Tag>;
    }
  };

  const onDocOpen = async ({ id }) => {
    const doc = await API.Docs.getDoc({ id });

    if (doc.error) {
      return message.error("Невозможно открыть документ", 2);
    }

    setDoc(doc.data, setModalVisible(true));
  };

  const onDocClose = (doc) => {
    setDoc(null, setModalVisible(false));
  };

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (data) => <h4 className="m-0">{data}</h4>,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (data) => getStatus(data),
    },
    {
      title: "Подвязанный документ",
      dataIndex: "parent_id",
      key: "parent_id",
      align: "center",
      render: (data, all) =>
        data ? (
          <Link to={`/docs/edit/${all.parent.id}`}>
            <a>{all.parent.name}</a>
          </Link>
        ) : (
          <Tag>Нет</Tag>
        ),
    },
    {
      title: "Посл. изменение",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (data) => (
        <p className="m-0">{moment(data).format("HH:mm DD.MM.YYYY")}</p>
      ),
    },
    {
      dataIndex: "actions",
      key: "actions",
      render: (_, all) => [
        <Button type="link" onClick={() => onDocOpen({ id: all.id })}>
          Посмотреть
        </Button>,
        <Button
          type="primary"
          onClick={() => props.onDocSelected({ id: all.id })}
        >
          Выбрать
        </Button>,
      ],
    },
  ];

  return (
    <Modal
      onCancel={props.onDocModalClose}
      visible={visible}
      closable
      footer={null}
      title="Документы артиста"
      width={1050}
    >
      <Tabs onChange={setCurrentTab} defaultActiveKey="1">
        <TabPane
          forceRender
          tab={
            <Badge
              dot={R.find(
                (doc) => doc.status === "new",
                R.pathOr([], ["incoming"], tableData)
              )}
            >
              Входящие
            </Badge>
          }
          key="1"
        >
          <Table
            key="incoming"
            className="tracks-table"
            onChange={getIncomingDocs}
            pagination={pagination.incoming}
            dataSource={tableData?.incoming || []}
            columns={columns}
            loading={isLoading}
          />
        </TabPane>
        <TabPane forceRender tab="Исходящие" key="2">
          <Table
            key="outgoing"
            className="tracks-table"
            onChange={getOutgoingDocs}
            pagination={pagination.outgoing}
            dataSource={tableData?.outgoing || []}
            columns={columns}
            loading={isLoading}
          />
        </TabPane>
      </Tabs>

      <DocModal doc={doc} visible={modalVisible} onClose={onDocClose} />
    </Modal>
  );
}
