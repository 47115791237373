import { Layouts } from "@components";
import { Button, Table, message, Tag, Divider, Input, Select } from "antd";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PageHeader } from "antd";
import moment from "moment";
import * as API from "@api/admin";

export default function Albums() {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [search, setSearch] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  const onSearch = async (s) => {
    // setSearch(s);
  };

  const getAlbums = async (p) => {
    setIsLoading(true);

    const _res = await API.Albums.getAlbums({
      ...search,
      limit: p.pageSize,
      offset: p.current * p.pageSize - p.pageSize,
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Невозможно загрузить данные", 2);
    }

    setTableData(
      _res.data,
      setPagination({
        ...pagination,
        ...p,
      })
    );
  };

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Albums.getAlbums({
        ...search,
        limit: pagination.pageSize,
        offset: pagination.pageSize - pagination.pageSize,
      });

      setIsLoading(false);

      if (_res.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      setTableData(
        _res.data,
        setPagination({
          ...pagination,
          current: 1,
          total: _res.pagination.total,
        })
      );
    }

    fetchInitData();
  }, [search]);

  const getStatus = (id) => {
    switch (id) {
      case "new":
        return (
          <Tag style={{ margin: 0 }} color="blue">
            Новый
          </Tag>
        );

      case "pending":
        return (
          <Tag style={{ margin: 0 }} color="orange">
            Ожидает модерации
          </Tag>
        );

      case "rejected":
        return (
          <Tag style={{ margin: 0 }} color="red">
            Отклонен
          </Tag>
        );

      case "awaiting_artist":
        return (
          <Tag style={{ margin: 0 }} color="orange">
            Ожидает артиста
          </Tag>
        );

      case "published":
        return (
          <Tag style={{ margin: 0 }} color="green">
            Опубликован
          </Tag>
        );

      case "opened":
        return <Tag style={{ margin: 0 }}>Посмотрен</Tag>;

      case "removed":
        return (
          <Tag style={{ margin: 0 }} color="red">
            Снят с ротации
          </Tag>
        );

      default:
        return <Tag style={{ margin: 0 }}>Загружен</Tag>;
    }
  };

  const columns = [
    {
      title: "Артист",
      dataIndex: "artist",
      key: "artist",
      render: (data) =>
        data ? (
          <a href={`/artists/view/${data.id}`}>{data.name}</a>
        ) : (
          <Tag>Нет артиста</Tag>
        ),
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (data) => <h4 className="m-0">{data}</h4>,
    },
    {
      title: "Кол-во треков",
      dataIndex: "tracks",
      key: "tracks",
      align: "center",
      render: (data) => (data ? data.length : 0),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (data) => getStatus(data),
    },
    {
      title: "Информация",
      dataIndex: "info",
      key: "info",
      align: "center",
      render: (_, data) => <div>{data.upc && <Tag>UPC: {data.upc}</Tag>}</div>,
    },
    {
      title: "Посл. изменение",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (data) => (
        <p className="m-0">{moment(data).format("HH:mm DD.MM.YYYY")}</p>
      ),
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (_, all) => (
        <Link to={`/albums/edit/${all.id}`}>
          <Button>Открыть</Button>
        </Link>
      ),
    },
  ];

  return (
    <Layouts.AdminLayout className="tracks-page">
      <PageHeader
        className="site-page-header"
        title="Треки"
        subTitle="Управляйте загруженными альбомами"
      />
      <Divider />

      <h3>Поиск альбомов</h3>

      <div>
        <div className="row">
          <div className="mb-2 mt-2 col-12">
            <Input
              onChange={(e) =>
                setSearch({ ...search, name: e.target.value || null })
              }
              className="w-100"
              placeholder="Поиск по названию"
            />
          </div>
          <div className="mb-2 mt-2 col-12 col-md-4">
            <Input
              onChange={(e) =>
                setSearch({ ...search, artist: e.target.value || null })
              }
              className="w-100"
              placeholder="Поиск по артисту"
            />
          </div>
          <div className="mb-2 mt-2 col-12 col-md-4">
            <Input
              onChange={(e) =>
                setSearch({ ...search, upc: e.target.value || null })
              }
              className="w-100"
              placeholder="Поиск по UPC"
            />
          </div>
          <div className="mb-2 mt-2 col-12 col-md-4">
            <Select
              onChange={(status) => setSearch({ ...search, status })}
              className="w-100"
              placeholder="Поиск по статусу"
              allowClear
            >
              <Select.Option value="new">Новый</Select.Option>
              <Select.Option value="pending">Ожидает модерации</Select.Option>
              <Select.Option value="rejected">Отклонен</Select.Option>
              <Select.Option value="awaiting_artist">
                Ожидает артиста
              </Select.Option>
              <Select.Option value="published">Опубликован</Select.Option>
              <Select.Option value="opened">Просмотрен</Select.Option>
              <Select.Option value="removed">Снят с ротации</Select.Option>
            </Select>
          </div>
        </div>
      </div>

      <Divider />
      <Table
        onChange={getAlbums}
        className="tracks-table"
        pagination={pagination}
        dataSource={tableData || []}
        columns={columns}
        loading={isLoading}
      />
    </Layouts.AdminLayout>
  );
}
