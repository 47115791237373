import React, { Fragment, useEffect, useState, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import * as API from "@api";
import * as Pages from "@pages";
import { ProtectedRoute, RoutesHandler, Loading } from "@components";
import * as R from "ramda";
import "@styles/styles.scss";
import "antd/dist/antd.css";

export default function App(params) {
  const [isLoading, setIsLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [hasArtist, setHasArtist] = useState(false);
  const onSignIn = (user) => setUser(user, (document.location.href = "/"));
  const onSignOut = () => setUser(null);

  useEffect(() => {
    async function authWorker() {
      const res = {
        status: await API.Auth.getStatus(),
        user: await API.Users.getDetails(),
        artist: await API.Artists.getArtist(),
      };

      if (signedIn !== res.status.signed_in) {
        setSignedIn(res.status.signed_in);
      }

      if (user !== res.user.data) {
        setUser(res.user.data);
      }

      if (R.pathOr(null, ["artist", "data", "id"], res)) {
        setHasArtist(true);
      }

      setIsLoading(false);
    }

    authWorker();
  }, []);

  return (
    <Router>
      <Switch>
        {isLoading ? (
          <Loading />
        ) : (
          <Suspense fallback={<div>Загрузка...</div>}>
            <Route
              exact
              path="/signin"
              render={(props) => (
                <Pages.SignIn
                  signedIn={signedIn}
                  user={user}
                  onSignOut={onSignOut}
                  onSignIn={onSignIn}
                  {...props}
                  {...params}
                />
              )}
            />

            <Route
              exact
              path="/logout"
              render={(props) => (
                <Pages.Logout
                  signedIn={signedIn}
                  user={user}
                  onSignOut={onSignOut}
                  onSignIn={onSignIn}
                  {...props}
                  {...params}
                />
              )}
            />

            {signedIn ? (
              <RoutesHandler
                hasArtist={hasArtist}
                user={user}
                signedIn={signedIn}
              />
            ) : (
              <ProtectedRoute />
            )}
          </Suspense>
        )}
      </Switch>
    </Router>
  );
}
