import { Loading, ContractViewer } from "@components";
import { message } from "antd";
import { useParams } from "react-router-dom";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import { PageHeader } from "antd";
import config from "@config";
import * as API from "@api/admin";
import * as R from "ramda";

export default function ViewContract(params) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [contract, setContract] = useState(null);

  useEffect(() => {
    // Подгружаем данные
    async function fetchInitData() {
      let _res = await API.Contracts.getContract({ id });

      if (_res.error) {
        return message.error("Невозможно загрузить договор", 2);
      }

      setContract(_res.data);
      setIsLoading(false);
    }

    fetchInitData();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return <ContractViewer contract={contract} />;
}
