import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Tabs, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import MaskedInput from "antd-mask-input";
import moment from "moment";
import config from "@config";
import * as API from "@api";

const { TabPane } = Tabs;

function SignInPage(params) {
  const regFormRef = useRef(null);
  const [isRequestingSMS, setIsRequestingSMS] = useState(false);
  const [lastSMSRequest, setLastSMSRequest] = useState(null);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    firebase.initializeApp(config.firebase);

    // Зарегистрировать reCaptcha на странице
    try {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "onRegistrationSubmit",
        {
          size: "invisible",
          callback: async function (response) {
            setToken(response); // сохраняем токен

            // Если уже зарегались и нужно просто запросить смс еще раз
            if (lastSMSRequest && token) {
              await onSMSRequest();
              return;
            }

            // Отправляем форму
            return regFormRef.current.submit();
          },
        }
      );

      (async () => await window.recaptchaVerifier.render())();

      return () => window.recaptchaVerifier.clear();
    } catch (error) {
      return;
    }
  }, []);

  const router = useHistory();

  /**
   *
   * Отправляем запрос на регистраци на сервер.
   * Возвращает нового юзера в случае успешной рег.
   *
   * @param  {} data
   */
  const onRegistrationSubmit = async (data) => {
    setIsLoading(true);

    if (!token) {
      return message.error("Невозможно проверить reCaptcha", 2);
    }

    if (!data.tel) {
      return message.error("Пожалуйста, введите номер телефона", 2);
    }

    // Отправляем запрос + нормализуем телефон
    const _res = await API.Auth.register({
      ...data,
      tel: data.tel.replace(/[+, ]/g, ""),
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error(_res.message, 2);
    }

    message.success("Успешная регистрация. Пожалуйста, подтвердите номер.", 2);
    await onSMSRequest();
  };

  /**
   * Запрашиваем отправку СМС-кода
   */
  const onSMSRequest = async () => {
    setIsRequestingSMS(true);
    setLastSMSRequest(moment());

    const _res = await API.Auth.requestSMS({ token });

    if (_res.error) {
      return message.error(_res.message, 2);
    }

    message.info("Сообщение с кодом выслано на ваш номер", 2);
  };

  /**
   *
   * Запрашиваем у сервера подтверждение кода
   *
   * @param  {} data
   */
  const onSMSConfirm = async (data) => {
    setIsLoading(true);
    const _res = await API.Auth.confirmSMS({ token, code: data.code });
    setIsLoading(false);

    if (_res.error) {
      return message.error("Неверный код, попробуйте еще раз");
    }

    return router.push("/");
  };

  /**
   *
   * Выполняем вход в аккаунт
   *
   * @param  {} data
   */
  const onSignin = async (data) => {
    setIsLoading(true);
    const _res = await API.Auth.signin(data);
    setIsLoading(false);

    if (_res.error) {
      return message.error("Неверные данные входа", 2);
    }

    const user = await API.Users.getDetails();
    params.onSignIn(user.data, router.push("/"));
  };

  return (
    <div className="container container-fluid signin">
      <div className="row">
        <div
          className="signin-bgr"
          style={{
            background: "url(/img/bgr.jpg) center center no-repeat",
            backgroundSize: "cover",
          }}
        />
        <div className="signin-form">
          <img
            src="/img/logo.svg"
            className="logo"
            alt="Musan Distribution Platform"
          />

          <Tabs
            prere
            style={{ width: "100%" }}
            defaultActiveKey="1"
            onChange={() => {}}
          >
            <TabPane forceRender tab="Войти" key="1">
              <Form
                name="signin"
                className="form-container"
                onFinish={onSignin}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите эл.почту",
                    },
                  ]}
                >
                  <Input
                    size="default"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Эл.почта"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Пожалуйста, введите пароль" },
                  ]}
                >
                  <Input
                    size="default"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Пароль"
                  />
                </Form.Item>

                <div className="d-flex justify-content-between align-items-center">
                  <Form.Item style={{ margin: 0 }}>
                    <Button
                      loading={isLoading}
                      size="default"
                      type="primary"
                      htmlType="submit"
                    >
                      Отправить
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </TabPane>
            <TabPane forceRender tab="Зарегистрироваться" key="2">
              <Form
                ref={regFormRef}
                name="registration"
                className="form-container"
                onFinish={onRegistrationSubmit}
                hidden={isRequestingSMS}
              >
                <Form.Item
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите ваше имя",
                    },
                  ]}
                >
                  <Input size="default" placeholder="Имя" />
                </Form.Item>

                <Form.Item
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите фамилию",
                    },
                  ]}
                >
                  <Input size="default" placeholder="Фамилия" />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите эл.почту",
                    },
                  ]}
                >
                  <Input size="default" placeholder="Эл.почта" />
                </Form.Item>

                <Form.Item
                  name="tel"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите номер телефона",
                    },
                  ]}
                >
                  <MaskedInput
                    mask="+7 111 1111111"
                    placeholder="Телефон (напр. +7 701 1234567)"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите пароль",
                    },
                  ]}
                >
                  <Input.Password size="default" placeholder="Пароль" />
                </Form.Item>

                <div className="d-flex justify-content-between align-items-center">
                  <Form.Item style={{ margin: 0 }}>
                    <Button
                      id="onRegistrationSubmit"
                      loading={isLoading}
                      size="default"
                      type="primary"
                      htmlType="submit"
                    >
                      Продолжить
                    </Button>
                  </Form.Item>
                </div>
              </Form>
              <Form
                name="sms"
                hidden={!isRequestingSMS}
                className="form-container"
                onFinish={onSMSConfirm}
                layout="vertical"
              >
                <Form.Item>
                  <h3>Мы отправили код на указанный вами номер.</h3>
                  <p className="mb-0">
                    Пожалуйста, введите код, отправленный на ваш номер ниже.
                  </p>
                </Form.Item>
                <Form.Item
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите полученный вами код",
                    },
                  ]}
                >
                  <Input size="default" placeholder="Код" />
                </Form.Item>

                <div className="d-flex justify-content-between align-items-center">
                  <Form.Item style={{ margin: 0 }}>
                    <Button
                      loading={isLoading}
                      size="default"
                      type="primary"
                      htmlType="submit"
                    >
                      Отправить
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </TabPane>
          </Tabs>
          <div id="recaptcha" />
          <copy>© MUSAN DIGITAL {new Date().getFullYear()}</copy>
        </div>
      </div>
    </div>
  );
}

export default SignInPage;
