import React, { Fragment } from "react";
import { Admin } from "@pages";
import { ProtectedRoute } from "@components";

export default function AdminRoutes({ signedIn = false }) {
  return (
    <Fragment>
      <ProtectedRoute
        exact
        path="/"
        signedIn={signedIn}
        component={Admin.Dashboard}
      />
      <ProtectedRoute
        exact
        path="/applications"
        signedIn={signedIn}
        component={Admin.Applications}
      />
      <ProtectedRoute
        exact
        path="/applications/view/:id"
        signedIn={signedIn}
        component={Admin.ViewApplication}
      />
      <ProtectedRoute
        exact
        path="/contracts/view/:id"
        signedIn={signedIn}
        component={Admin.ViewContract}
      />
      <ProtectedRoute
        exact
        path="/artists"
        signedIn={signedIn}
        component={Admin.Artists}
      />
      <ProtectedRoute
        exact
        path="/artists/view/:id"
        signedIn={signedIn}
        component={Admin.ViewArtist}
      />
      <ProtectedRoute
        exact
        path="/artists/edit/:id"
        signedIn={signedIn}
        component={Admin.EditArtist}
      />
      <ProtectedRoute
        exact
        path="/users"
        signedIn={signedIn}
        component={Admin.Users}
      />
      <ProtectedRoute
        exact
        path="/users/edit/:id"
        signedIn={signedIn}
        component={Admin.EditUser}
      />
      <ProtectedRoute
        exact
        path="/reports"
        signedIn={signedIn}
        component={Admin.Reports}
      />
      <ProtectedRoute
        exact
        path="/reports/new/:id"
        signedIn={signedIn}
        component={Admin.NewReport}
      />
      <ProtectedRoute
        exact
        path="/reports/edit/:id"
        signedIn={signedIn}
        component={Admin.EditReport}
      />
      <ProtectedRoute
        exact
        path="/reports/view/:id"
        signedIn={signedIn}
        component={Admin.ViewReport}
      />
      <ProtectedRoute
        exact
        path="/docs"
        signedIn={signedIn}
        component={Admin.Docs}
      />
      <ProtectedRoute
        exact
        path="/docs/new/:id"
        signedIn={signedIn}
        component={Admin.NewDoc}
      />
      <ProtectedRoute
        exact
        path="/docs/edit/:id"
        signedIn={signedIn}
        component={Admin.EditDoc}
      />
      <ProtectedRoute
        exact
        path="/tracks"
        signedIn={signedIn}
        component={Admin.Tracks}
      />
      <ProtectedRoute
        exact
        path="/tracks/new/:id"
        signedIn={signedIn}
        component={Admin.NewTrack}
      />
      <ProtectedRoute
        exact
        path="/tracks/edit/:id"
        signedIn={signedIn}
        component={Admin.EditTrack}
      />
      <ProtectedRoute
        exact
        path="/albums"
        signedIn={signedIn}
        component={Admin.Albums}
      />
      <ProtectedRoute
        exact
        path="/albums/edit/:id"
        signedIn={signedIn}
        component={Admin.EditAlbum}
      />
      <ProtectedRoute
        exact
        path="/profile"
        signedIn={signedIn}
        component={Admin.Profile}
      />
    </Fragment>
  );
}
