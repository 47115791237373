import axios from "axios";
import config from "@config";

const API = `${config.api}/documents`;

// Возвращает входящие документы артиста
export const getIncomingDocs = (params) =>
  axios
    .get(`${API}/incoming`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает исходящие документы артиста
export const getOutgoingDocs = (params) =>
  axios
    .get(`${API}/outgoing`, { params, withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Создает новый документ
export const createDoc = (data) =>
  axios
    .post(`${API}/create`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

// Возвращает документ артиста
export const getDoc = ({ id }) =>
  axios
    .get(`${API}/id/${id}`, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
