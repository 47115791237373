// eslint-disable-next-line import/no-anonymous-default-export
export default {
  env: process.env.NODE_ENV || process.env.ENV,
  app: {
    port: process.env.PORT,
    logger: "dev",
    basePath: process.env.REACT_APP_PATH_BASE,
  },
  api: process.env.REACT_APP_PATH_API,
  paths: {
    s3: process.env.REACT_APP_PATH_S3,
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGESENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  },
};
