import axios from "axios";
import config from "@config";

const API = `${config.api}/artists`;

// Возвращает текущего артиста
export const getArtist = () =>
  axios
    .get(API, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
