import { Layouts } from "@components";
import { Button, Table, message, Tag } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PageHeader } from "antd";
import moment from "moment";
import * as API from "@api/admin";
import config from "@config";
import * as R from "ramda";

export default function Applications() {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Applications.getApplications({
        limit: pagination.pageSize,
        offset: pagination.current * pagination.pageSize - pagination.pageSize,
      });

      setIsLoading(false);

      if (_res.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      setTableData(
        _res.data,
        setPagination({
          ...pagination,
          total: _res.pagination.total,
        })
      );
    }

    fetchInitData();
  }, []);

  const getApplications = async (pagination) => {
    setIsLoading(true);

    const _res = await API.Applications.getApplications({
      limit: pagination.pageSize,
      offset: pagination.current * pagination.pageSize - pagination.pageSize,
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Невозможно загрузить данные", 2);
    }

    setTableData(_res.data, setPagination(pagination));
  };

  const getStatus = (status) => {
    switch (status) {
      case "new":
        return <Tag color="blue">Новый</Tag>;
      case "pending":
        return <Tag>Ожидает модерации</Tag>;
      case "published":
        return <Tag className="green">Одобрен</Tag>;
      case "rejected":
        return <Tag className="red">Отклонен</Tag>;
      case "awaiting_artist":
        return <Tag className="orange">Ожидает артиста</Tag>;

      default:
        return <Tag>Просмотрен</Tag>;
    }
  };

  const columns = [
    {
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (data) => (
        <img
          src={data ? `${config.paths.s3}/${data}` : "/img/icon-track.svg"}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/img/icon-track.svg";
          }}
          className="track-img"
          style={{ margin: "0 auto" }}
          alt="Изображение артиста"
        />
      ),
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (data) => <h4 className="m-0">{data}</h4>,
    },
    {
      title: "Пользователь",
      dataIndex: "user",
      key: "user",
      align: "center",
      render: (data, all) =>
        data && (
          <Link to={`/artists/view/${data.id}`}>
            <a>
              {data.first_name} {data.last_name}
            </a>
          </Link>
        ),
    },
    {
      title: "Превью-трек",
      dataIndex: "track",
      key: "track",
      align: "center",
      render: (data) => (data ? <Tag color="green">Есть</Tag> : <Tag>Нет</Tag>),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: getStatus,
    },
    {
      title: "Дата заявки",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (data) => (
        <p className="m-0">{moment(data).format("HH:mm DD.MM.YYYY")}</p>
      ),
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (data, all) => (
        <Link to={`/applications/view/${all.id}`}>
          <Button type="primary">Открыть</Button>
        </Link>
      ),
    },
  ];

  return (
    <Layouts.AdminLayout className="tracks-page">
      <PageHeader
        className="site-page-header"
        title="Заявки на регистрацию исполнителя"
        subTitle="Управляйте входящими заявками на регистрацию исполнителя на платформе"
      />
      <Table
        className="tracks-table"
        onChange={getApplications}
        pagination={pagination}
        dataSource={tableData || []}
        columns={columns}
        loading={isLoading}
      />
    </Layouts.AdminLayout>
  );
}
