import { Layouts, Loading } from "@components";
import { Form, Input, Button, Upload, Divider, Checkbox, message } from "antd";
import { UploadOutlined, RedoOutlined } from "@ant-design/icons";
import MaskedInput from "antd-mask-input";
import { useState, useRef, useEffect } from "react";
import * as API from "@api/admin";

export default function Profile() {
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const getInitData = async () => {
      const _res = await API.Users.getDetails();

      if (_res.error) {
        return message.error("Невозможно загрузить данные", 1);
      }

      setFormData(_res.data);
      setIsLoading(false);
    };

    getInitData();
  }, []);

  const onDetailsUpdate = async (data) => {
    setIsLoading(true);

    if (data.tel) {
      data.tel = data.tel.replace(/[+, ]/g, "");
    }

    const _res = await API.Users.updateSelf(data);
    setIsLoading(false);

    if (_res.error) {
      return message.error("Произошла ошибка", 1);
    }

    return message.success("Данные успешно обновлены", 1);
  };

  const onPasswordUpdate = (data) => {
    if (data.password.length < 6) {
      return message.error("Пароль должен быть не менее 6 символов", 1);
    }

    if (data.password !== data.password_confirm) {
      return message.error("Пароли не совпадают", 1);
    }

    onDetailsUpdate(data);
  };

  if (!formData) {
    return (
      <Layouts.AdminLayout>
        <Loading />
      </Layouts.AdminLayout>
    );
  }

  const detailsform = (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onDetailsUpdate}
      onFinishFailed={() => {}}
      autoComplete="off"
      style={{ maxWidth: 500, margin: "50px auto" }}
      labelAlign="left"
      initialValues={formData}
    >
      <Form.Item
        label="Имя"
        name="first_name"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Фамилия"
        name="last_name"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Эл.почта"
        name="email"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input htmlType="email" />
      </Form.Item>

      <Divider />

      <Form.Item className="mt-4">
        <Button loading={isLoading} type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );

  const telform = (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onDetailsUpdate}
      onFinishFailed={() => {}}
      autoComplete="off"
      style={{ maxWidth: 500, margin: "50px auto" }}
      labelAlign="left"
      initialValues={formData}
    >
      <Form.Item
        label="Телефон"
        name="tel"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <MaskedInput
          mask="+7 111 1111111"
          placeholder="Телефон (напр. +7 701 1234567)"
        />
      </Form.Item>

      <Divider />

      <Form.Item className="mt-4">
        <Button loading={isLoading} type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );

  const passwordform = (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onPasswordUpdate}
      onFinishFailed={() => {}}
      autoComplete="off"
      style={{ maxWidth: 500, margin: "50px auto" }}
      labelAlign="left"
    >
      <Form.Item
        label="Новый пароль"
        name="password"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input.Password placeholder="Введите новый пароль" />
      </Form.Item>

      <Form.Item
        label="Повторите пароль"
        name="password_confirm"
        rules={[
          { required: true, message: "Это поле обязательно к заполнению" },
        ]}
      >
        <Input.Password placeholder="Повторите новый пароль" />
      </Form.Item>

      <Divider />

      <Form.Item className="mt-4">
        <Button loading={isLoading} type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Layouts.AdminLayout>
      <h2 className="m-0">Данные пользователя</h2>
      {detailsform}
      <h2>Номер телефона</h2>
      {telform}
      <h2>Пароль</h2>
      {passwordform}
    </Layouts.AdminLayout>
  );
}
