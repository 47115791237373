import { Button } from "antd";
import { useState } from "react";
import { Image } from "antd";
import {
  CaretRightOutlined,
  PauseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import ReactPlayer from "react-player";

export default function Player({
  url,
  title = "Название трека",
  subtitle = "Артист",
  image,
}) {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="player">
      <div className="controls">
        <Button
          onClick={togglePlay}
          shape="circle"
          icon={isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
          type={isPlaying ? "default" : "primary"}
        />
        <Image
          src={image || "/img/icon-track.svg"}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/img/icon-track.svg";
          }}
          className="cover"
          alt="Изображение трека"
          preview={{
            mask: null,
          }}
          style={{ cursor: "pointer" }}
        />
        <div className="meta">
          <h4 className="title">{title}</h4>
          <h4 className="subtitle">{subtitle}</h4>
        </div>
      </div>
      <a href={url} target="_blank">
        <Button
          size="small"
          icon={<SaveOutlined />}
          style={{ margin: "10px 0" }}
        >
          Скачать
        </Button>
      </a>
      <ReactPlayer className="player-invisible" playing={isPlaying} url={url} />
    </div>
  );
}
