import { Layouts, Loading } from "@components";
import {
  Form,
  Input,
  Button,
  Upload,
  Divider,
  Select,
  message,
  DatePicker,
  PageHeader,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import * as API from "@api/admin";
import * as R from "ramda";
import DocSelector from "../reports/docselector";

export default function NewDocument() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [docType, setDocType] = useState(1);
  const [relatedDocId, setRelatedDocId] = useState(null);
  const [docModalVisible, setDocModalVisible] = useState(false);
  const router = useHistory();

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Users.getUser({ id });

      if (_res.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      setUser(_res.data);
      setIsLoading(false);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("target_user_id", user.id);
    formData.append("type", docType);

    if (relatedDocId) {
      formData.append("parent_id", relatedDocId);
    }

    if (data.type === 1) {
      const file = R.pathOr(null, ["docfile", "file"], data);

      if (file) {
        formData.append("docfile", file);
      } else {
        setIsLoading(false);
        return message.error("Пожалуйста, загрузите файл отчета", 1);
      }
    } else {
      formData.append("url", data.url);
    }

    const _res = await API.Docs.createDoc(formData);

    setIsLoading(false);

    if (_res.error) {
      return message.error("Не удалось создать отчет", 2);
    }

    message.success("Документ загружен", 1, () => router.push("/docs"));
  };

  if (isLoading) {
    return (
      <Layouts.AdminLayout>
        <Loading />
      </Layouts.AdminLayout>
    );
  }

  return (
    <Layouts.AdminLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Новый документ"
        subTitle="Загрузите новый документ для артиста на платформу"
      />
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFormSubmit}
        onFinishFailed={() => {}}
        autoComplete="off"
        style={{ maxWidth: 600, margin: "50px auto" }}
        labelAlign="left"
      >
        <Form.Item
          name="target_user_id"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
          hidden
          initialValue={user.id}
        >
          <Input type="hidden" />
        </Form.Item>

        <Form.Item
          label="Получатель"
          name="user"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
          initialValue={`${user.first_name} ${user.last_name} (${
            user.artist ? user.artist.name : "Нет профиля артиста"
          })`}
          disabled
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Название"
          name="name"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input placeholder="Напр. Акт выполненных работ" />
        </Form.Item>

        <Divider />

        <Form.Item
          label="Тип документа"
          name="type"
          allowClear={false}
          initialValue={docType}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Select onChange={setDocType}>
            <Select.Option value={1}>Файл</Select.Option>
            <Select.Option value={2}>
              Внешняя ссылка на док-т (напр. Google Drive)
            </Select.Option>
          </Select>
        </Form.Item>

        {docType === 1 && (
          <Form.Item
            name="docfile"
            label="Файл"
            extra="Загрузите документ сюда (макс. 20 МБ)"
            rules={[
              {
                validator: (_, value) => {
                  if (value.fileList.length > 0) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error("Это поле обязательно к заполнению")
                  );
                },
                required: true,
                message: "Это поле обязательно к заполнению",
              },
            ]}
          >
            <Upload beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />}>Выбрать файл</Button>
            </Upload>
          </Form.Item>
        )}

        {docType === 2 && (
          <Form.Item
            name="url"
            label="Ссылка на документ"
            extra="Введите ссылку на внешний документ"
            rules={[
              {
                required: true,
                message: "Это поле обязательно к заполнению",
              },
            ]}
          >
            <Input type="url" />
          </Form.Item>
        )}

        <Divider />

        <Form.Item
          extra="Если это дочерний или связанный с другим документ, пожалуйста, выберите"
          label="Подвязанный документ"
          name="parent_id"
        >
          {relatedDocId ? (
            <Button danger type="primary" onClick={() => setRelatedDocId(null)}>
              Отвязать документ
            </Button>
          ) : (
            <Button onClick={() => setDocModalVisible(true)}>
              Выбрать документ
            </Button>
          )}
        </Form.Item>

        <Divider />

        <Form.Item className="mt-4">
          <Button
            loading={isLoading}
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
          >
            Отправить
          </Button>
        </Form.Item>
      </Form>
      <DocSelector
        onDocModalClose={() => setDocModalVisible(false)}
        onDocSelected={({ id }) =>
          setRelatedDocId(id, setDocModalVisible(false))
        }
        visible={docModalVisible}
        user_id={user.id}
      />
    </Layouts.AdminLayout>
  );
}
