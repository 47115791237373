import { ReportModal } from "@components";
import { Button, Table, message, Tag } from "antd";
import { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import * as API from "@api/admin";
import "moment/locale/ru";

export default function ArtistReports(params) {
  const { artist_id } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [report, setReport] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  });

  useEffect(() => {
    async function fetchInitData() {
      const _res = await API.Reports.getReports({
        artist_id,
        limit: pagination.pageSize,
        offset: pagination.current * pagination.pageSize - pagination.pageSize,
      });

      setIsLoading(false);

      if (_res.error) {
        return message.error("Невозможно загрузить отчеты", 2);
      }

      setTableData(
        _res.data,
        setPagination({
          ...pagination,
          total: _res.pagination.total,
        })
      );
    }

    fetchInitData();
  }, []);

  const getReports = async (pagination) => {
    setIsLoading(true);

    const _res = await API.Reports.getReports({
      limit: pagination.pageSize,
      offset: pagination.current * pagination.pageSize - pagination.pageSize,
    });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Невозможно загрузить данные", 2);
    }

    setTableData(
      {
        ...tableData,
        reports: _res.data,
      },
      setPagination(pagination)
    );
  };

  const getStatus = (id) => {
    switch (id) {
      case "new":
        return (
          <Tag style={{ margin: 0 }} color="blue">
            Новый
          </Tag>
        );

      case "opened":
        return <Tag style={{ margin: 0 }}>Посмотрен</Tag>;

      case "rejected":
        return (
          <Tag style={{ margin: 0 }} color="red">
            Отклонен
          </Tag>
        );

      default:
        return <Tag style={{ margin: 0 }}>Загружен</Tag>;
    }
  };

  const onReportOpen = async ({ id }) => {
    const report = await API.Reports.getReport({ id });

    if (report.error) {
      return message.error("Невозможно открыть отчет", 2);
    }

    setReport(report.data, setModalVisible(true));
  };

  const onReportClose = (report) => {
    setReport(null, setModalVisible(false));
  };

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (data) => <h4 className="m-0">{data}</h4>,
    },
    {
      title: "Период отчета",
      dataIndex: "period",
      key: "period",
      align: "center",
      render: (_, all) => (
        <div className="row">
          <div className="col-12" style={{ textTransform: "capitalize" }}>
            {moment(all.period_start).locale("ru").format("MMMM YYYY")} —{" "}
            {moment(all.period_end).locale("ru").format("MMMM YYYY")}
          </div>
        </div>
      ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (data) => getStatus(data),
    },
    {
      title: "Подпись",
      dataIndex: "requires_artist_signature",
      key: "requires_artist_signature",
      align: "center",
      render: (data, all) => {
        if (!data) {
          return <Tag style={{ margin: 0 }}>Подпись не требуется</Tag>;
        }

        if (data && all.artist_signed) {
          return (
            <Tag style={{ margin: 0 }} color="green">
              Документ подписан
            </Tag>
          );
        }

        return (
          <Tag style={{ margin: 0 }} color="blue">
            Можно подписать
          </Tag>
        );
      },
    },
    {
      title: "Посл. изменение",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (data) => (
        <p className="m-0">{moment(data).format("HH:mm DD.MM.YYYY")}</p>
      ),
    },
    {
      dataIndex: "actions",
      key: "actions",
      render: (_, all) => (
        <Button onClick={() => onReportOpen({ id: all.id })}>Открыть</Button>
      ),
    },
  ];

  return (
    <div>
      <Table
        className="tracks-table"
        pagination={pagination}
        onChange={getReports}
        dataSource={tableData || []}
        columns={columns}
        loading={isLoading}
        title={() => (
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h3 className="m-0">Отчеты</h3>
            <Link to={`/reports/new/${artist_id}`}>
              <Button type="primary" icon={<PlusOutlined />}>
                Новый отчет
              </Button>
            </Link>
          </div>
        )}
      />
      <ReportModal
        isAdmin
        report={report}
        visible={modalVisible}
        onClose={onReportClose}
      />
    </div>
  );
}
