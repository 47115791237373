import { Layouts, Loading, Player } from "@components";
import { Form, Input, Button, Upload, Divider, Select, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { PageHeader } from "antd";
import config from "@config";
import * as R from "ramda";
import * as API from "@api/admin";

export default function EditAlbum() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [album, setAlbum] = useState(null);
  const [tracks, setTracks] = useState([]);
  const router = useHistory();

  useEffect(() => {
    async function fetchInitData() {
      let _res = {
        album: await API.Albums.getAlbum({ id }),
      };

      _res.tracks = await API.Tracks.getTracks({
        artist_id: _res.album.data.artist_id,
      });

      if (_res.album.error || _res.tracks.error) {
        return message.error("Невозможно загрузить данные", 2);
      }

      setAlbum(_res.album.data);
      setTracks(_res.tracks.data);
      setIsLoading(false);
    }

    fetchInitData();
  }, []);

  const onFormSubmit = async (data) => {
    const tracks = data.tracks_raw || [];

    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("name_en", data.name_en);
    formData.append("tracks", JSON.stringify(tracks));
    formData.append("status", data.status);
    formData.append("upc", data.upc);

    if (R.pathOr(null, ["image", "file"], data)) {
      formData.append("image", data.image.file);
    }

    const _res = await API.Albums.updateAlbum({ id: album.id, data: formData });

    setIsLoading(false);

    if (_res.error) {
      return message.error("Не удалось обновить альбом", 2);
    }

    message.success("Альбом обновлен", 1, () => document.location.reload());
  };

  if (isLoading) {
    return (
      <Layouts.AdminLayout>
        <Loading />
      </Layouts.AdminLayout>
    );
  }

  return (
    <Layouts.AdminLayout>
      <PageHeader
        onBack={() => window.history.back()}
        className="site-page-header"
        title="Альбом артиста"
        subTitle="Редактируйте информацию об альбоме артиста"
      />
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFormSubmit}
        onFinishFailed={() => {}}
        autoComplete="off"
        style={{ maxWidth: 500, margin: "50px auto" }}
        labelAlign="left"
        initialValues={album}
      >
        <Form.Item
          label="Артист"
          name={["artist", "name"]}
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Название"
          name="name"
          rules={[
            { required: true, message: "Это поле обязательно к заполнению" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Название на англ." name="name_en">
          <Input />
        </Form.Item>

        <Form.Item
          extra="Выберите треки альбома"
          label="Треки"
          name="tracks_raw"
          initialValue={album.tracks.map((track) => track.id)}
          rules={[
            {
              required: true,
              message: "Выберите треки для альбома",
            },
          ]}
        >
          <Select
            allowClear
            mode="multiple"
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={tracks.map((track) => {
              return {
                label: track.name,
                value: track.id,
              };
            })}
          />
        </Form.Item>

        <Divider />

        <Form.Item label="UPC" name="upc">
          <Input />
        </Form.Item>

        <Divider />

        <Form.Item label="Статус" name="status">
          <Select allowClear={false}>
            <Select.Option value="new">Новый</Select.Option>
            <Select.Option value="pending">Ожидает модерации</Select.Option>
            <Select.Option value="rejected">Отклонен</Select.Option>
            <Select.Option value="awaiting_artist">
              Ожидает артиста
            </Select.Option>
            <Select.Option value="published">Опубликован</Select.Option>
            <Select.Option value="opened">Просмотрен</Select.Option>
            <Select.Option value="removed">Снят с ротации</Select.Option>
          </Select>
        </Form.Item>

        <Divider />

        <Form.Item
          name="image"
          label="Изображение"
          extra="Выберите новое изображение (макс. 5 МБ)"
        >
          <Upload
            beforeUpload={() => false}
            accept="image/*"
            name="image"
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Выбрать изображение</Button>
          </Upload>
        </Form.Item>

        <Divider />

        <Form.Item className="mt-4">
          <Button
            loading={isLoading}
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Layouts.AdminLayout>
  );
}
